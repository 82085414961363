@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@import "~react-image-gallery/styles/css/image-gallery.css";


:root{
  --red: #a61e22;
  --blue: #3c6d8d;
  --grey: #606161;
  --light-grey: #ccc;
  --ultra-light-grey: #f3f3f3;
  --black: #171716;
  --white: #FFF;
  --content-width: 1100px;
  --primary-font: 'Fira Sans', sans-serif;
  --primary-font-size: 22px;
}

/* General Styles */
* { margin: 0; padding: 0; box-sizing: border-box;}
html { font-family:  var(--primary-font); font-weight: 300; font-size: var(--primary-font-size);}
body { min-height: 100vh; color: var(--black);}
.App { min-height: 100vh;}
article { width: var(--content-width); margin: 35px auto;}
article.inner p { margin: 10px 0;}
article.inner p br { margin: 10px 0;}
/* section article.inner ol, section article.inner ul { margin-left: 40px;} */
section article.inner li { margin-bottom: 0px;}
section nav#user-menu li { margin-bottom: 10px;}
section.main { padding-bottom: 35px;}
a, a:visited { color: var(--black);}
a.button { background: var(--blue); padding: 0.5rem; color: var(--white); font-size: .8em; font-weight: bold; margin-bottom: 35px !important; text-decoration: none;}
a.button-red { background: var(--red); padding: 0.5rem; color: var(--white); font-size: .8em; font-weight: bold; margin-bottom: 35px !important; text-decoration: none;}
a.button:hover { background: var(--grey);}
a.button.goToDashboard { margin-top: 35px; display: inline-block;}
a.button.marginTop { margin-top: 35px; display: inline-block;}

article.inner.content { }
article.inner.content p { margin-bottom: 20px;}
article.inner.content ul { padding: 30px 0px 30px 60px;}
article.inner.content ul li { margin-bottom: 10px;}

/* Terms & Conditions */
div.terms ol ol { margin: 20px 0 20px 40px;}
div.terms li { padding-bottom: 10px;}

/* Headings */
h1:not(.case) { text-transform: capitalize;}
.sub { font-size: .5em; color: var(--light-grey); margin-left: 10px;}
.sub:before { content: '(';}
.sub:after { content: ')';}
h1 {
  font-size: 2rem;
}
h2 {
  font-size: 1.75rem;
}
h3 {
  font-size: 1.5rem;
}
h4 {
  font-size: 1.25rem;
}
h5 {
  font-size: 1rem;
}
h6 {
  font-size: 0.75rem;
}

/* Header */
header { width: 100%; position: relative; background: var(--white);}
/* header { width: 100%; border-bottom: 4px solid var(--black); position: relative; background: var(--white);} */
header article.inner { position: relative; margin: 10px auto; display: flex; justify-content: space-between; align-items: center; flex-wrap: wrap;}
header .search { font-size: 1.5rem; display: flex; align-items: center;}
header span.welcome { flex: 0 0 100%; text-align: right; display: flex; align-items: center; justify-content: end; cursor: pointer;}
header span.welcome svg { margin-left: 20px;}

/* Logo */
.logoWrapper {}
.logo { height: 100px; width: auto;}

/* Banner */
section.banner { width: 100%; max-height: 600px; overflow: hidden; position: relative;}
section.banner img { display: block; width: 100%;}
section.banner .searchBoxWrapper { display: flex; align-items: center; position: absolute; top: 0; right: 0; bottom: 0; left: 0; justify-content: center;}
section.banner .searchBoxWrapper button { margin-top: 0 !important;}
section.banner .searchBoxWrapper .serachBoxContent { }
section.banner .searchBoxWrapper .serachBoxContent h2 { font-size: 2em; color: var(--white); text-shadow: 2px 2px 2px #000; margin-bottom: 15px; }
section.banner .searchBox { background: rgba(60, 109, 141, .5); border: 1px solid var(--white); padding: 15px; display: flex; flex-direction: row; width: 100%; gap: 15px; position: relative;}
section.banner .searchBox .serviceSearchWrapper { flex: 0 0 70%;}
section.banner .searchBox .zipcodeSearchWrapper { flex: 1;}

/* Search Box MUI Overrides */
.MuiAutocomplete-root .MuiOutlinedInput-root { background: var(--white) !important; border-radius: 0 !important;}
.MuiAutocomplete-root label { margin-top: 0px !important;}
.MuiAutocomplete-root .MuiAutocomplete-endAdornment { top: calc(50% - 20px) !important;}
.MuiAutocomplete-root fieldset { top: 0px !important;}
.MuiAutocomplete-root label.MuiFormLabel-filled { display: none !important;}
.MuiAutocomplete-root label.Mui-focused { display: none !important;}
.MuiAutocomplete-root legend { background: transparent !important; display: none !important;}
.MuiAutocomplete-root legend span { background: transparent !important;}
.MuiAutocomplete-root .MuiOutlinedInput-root .MuiIconButton-root { color: var(--black) !important;}
.MuiAutocomplete-root .MuiOutlinedInput-root .MuiIconButton-root svg { fill: var(--black) !important;}
.MuiAutocomplete-popper { overflow: hidden !important;}
.zipcodeSearchWrapper .MuiOutlinedInput-root {height: 100%;}

/* Search Page Params */
.searchParams { margin-bottom: 20px; font-size: 14px; font-style: italic; color: var(--light-grey);}
.searchParams p { display: inline-block; margin-top: 0 !important;}
.contractorRow {
  padding-top: 3rem;
  padding-bottom: 3rem;
  border-bottom: 1px solid #BFBFBF;
  color: #4A4B4B;
}
.contractorList .container .contractorRow:first-child{
  padding-top: 0;
}
.titleButtonSection {
  position: relative;
}
.titleButtonSection h2 {
  display: inline;
  font-size: 24px;
  font-weight: 700;
}
.titleButtonSection h2 a {
  text-decoration: none;
  color: #4A4B4B;
}
.titleButtonSection .button-red { 
  position: absolute;
  right: 0;
  width: 8rem;
  font-weight: 500;
  font-size: 16px;
}
.titleButtonSection .button-red svg{
  position: absolute;
  right: 10px;
  top: 13px;
  font-size: 22px;
}
.titleButtonSection .searchRatingText {
  display: inline;
  color: #0074A9;
  font-weight: 700;
  font-family: 'Inter';
  vertical-align: text-bottom;
  padding-right: 0.7rem;
}
.searchRatingText {
  display: inline;
  color: #0074A9;
  font-weight: 700;
  font-family: 'Inter';
  vertical-align: text-bottom;
  padding-right: 0.7rem;
}
.searchRatingSection {
  padding-top: 0.5rem;
}
.categoryCol { 
  padding-bottom: 1rem; 
  padding-right: 0 !important;
}
.categoryRow {
  width: 80%;
}
.categoryTag {
  display: flex;
  justify-content: center;
  background: #F1F1F1;
  border-radius: 5px;
}
.categoryTag p {
  padding: 0 0.5rem;
  text-align: center;
  font-family: "inter";
  font-size: 14px;
  font-weight: 400;
  text-wrap: nowrap;
}
.contractorDescription {
  font-size: 18px;
  padding-bottom: 10px;
		--line-clamp: 3;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: var(--line-clamp);
		-webkit-box-orient: vertical;
		padding-bottom: 0;

}
.contractorLocation {
  padding-bottom: 1rem;
  font-size: 18px;
}
.contractorLocation svg{
  margin-right: 0.5rem;
}
.ratingSection {
  display: inline-block;
  color: #0074A9;
  font-size: 18px;
  font-weight: 700;
  font-family: 'Inter';
}
.ratingSection span {
  color: #C99E61;
  font-size: 28px;
}
ul.contractorList li .searchImageLink .cover img {min-height: fit-content;}
.contractorList {
  padding-left: 0;
}
.searchResultsH1 {
  font-size: 45px;
  font-weight: 400;
  font-family: inter;
}
ul.contractorList .pictureLi {
  height:auto;
  background-color: transparent;
}
ul.contractorList .pictureLi:hover .cover {
  position: relative;
}

/* Footer */
footer { text-align: center; background: var(--blue); margin: 0; display: inline-block; width: 100%;}
footer .sub__footer { background: var(--white); line-height: 60px; font-size: .7em;}
footer .sub__footer article { margin: 0 auto; display: flex; justify-content: space-between;}
footer a, footer a:visited { text-decoration: none; color: var(--white);}
footer .sub__footer a, .sub__footer footer a:visited { text-decoration: none; color: var(--black);}
footer h1, footer h2, footer h3, footer h4 { color: var(--white);}
footer .columnWrapper { display: flex; gap: 20px; align-items: center;}
footer ul { list-style: none;}
footer .column { flex: 0 0 calc(33% - (40px / 3));}
footer .column img { width: 100%; height: auto; margin-top: 20px;}
footer .social { color: var(--white); padding-bottom: 35px;}
footer .social li { display: inline-block; }
footer .social a { color: var(--white); font-size: 2.5em;}
footer .terms { font-size: 14px;}
footer .terms li { display: inline-block; margin: 0 10px;}
footer .terms li:after { content: '|'; margin-left: 20px; color: var(--white);}
footer .terms li:last-of-type:after { content: ''; margin-left: 0;}
footer .footer-links-ul { padding-left: 0;}

/* Navigation */
/* nav { display: inline-block; } */
nav a { color: var(--red); text-decoration: none; text-transform: uppercase; font-size: 24px;}
nav a:visited { color: var(--red);}
nav a:hover { color: var(--black);}
nav ul { list-style: none;}
/* nav ul li { display: inline-block; margin: 0 0 0 20px;} */
nav ul li svg { position: relative; top: 2px;}
#top-nav span.welcome { display: flex; align-items: center; padding-left: 20px;}

nav#main-menu { }
nav#top-nav { display: flex; flex-direction: row-reverse;}
nav#top-nav ul li { display: inline-block; margin-left: 20px;}
nav#top-nav .button { background: var(--blue); padding: 0.5rem;}
nav#top-nav .button a { color: var(--white); font-size: .8em; font-weight: bold;}
nav#top-nav .button.red { background: var(--red);}
/* nav#top-nav button.enabled { color: red;} */
nav#top-nav .button:hover { background: var(--black);}

footer nav#footer-menu {}
footer nav#footer-menu ul { columns: 3; column-gap: 20px;}
footer nav#footer-menu li { display: block; text-align: left;}
footer nav#footer-menu a { color: var(--white); font-size: .75em;}
footer nav#footer-menu a:hover { color: var(--red);}
nav#admin-nav li { margin-bottom: 10px;}
nav#user-menu li a, nav#contractor-menu li a, nav#admin-nav li a { margin-bottom: 0px; line-height: 50px; background: var(--ultra-light-grey); width: 100%; padding-left: 20px; display: inline-block;}
nav#user-menu li svg, nav#contractor-menu li svg, nav#admin-nav li svg { margin-right: 10px;}
/* nav#user-menu li:hover, nav#contractor-menu li:hover, nav#admin-nav li:hover { background: var(--red);} */
nav#user-menu li:hover a, nav#contractor-menu li:hover a, nav#admin-nav li:hover a { color: var(--white); background: var(--red);}
nav#user-menu a, nav#contractor-menu a, nav#admin-nav a { font-size: 20px;}
nav#user-menu, nav#contractor-menu, nav#admin-nav { margin-top: 20px;}
nav#user-menu li a.active, nav#admin-nav li a.active { border-right: 2px solid var(--red);}
nav#user-menu li a .subMenu { display: none; padding-left: 0;}
nav#user-menu li a.active .subMenu { display: block;}
nav#user-menu li a.active .subMenu li { background: var(--grey); color: var(--white); padding-left: 20px; margin-bottom: 0; position: relative; left: -20px;width: calc(100% + 20px);}
nav#user-menu li a.active .subMenu li:hover { color: var(--red);}
nav#user-menu li.logout, nav#admin-nav li.logout { background: var(--grey); color: var(--white); line-height: 50px; width: 100%; padding-left: 20px; display: inline-block;}
nav#user-menu li.logout:hover, nav#admin-nav li.logout:hover { background: var(--red); color: var(--white);}
nav#user-menu li.viewProfile { background: var(--blue) !important;}
nav#user-menu ul {padding-left: 0;}
/* Forms */
form { display: flex; flex-direction: column; justify-content: flex-start; flex-grow: 1; padding-bottom: 1rem; width: 50%;}
#editProfile form #profilePhotos .formContentWrapper { display: flex; gap: 20px;}
#editProfile form #profilePhotos .column { flex: 0 0 calc(50% - 10px);}
#editProfile form #profilePhotos .column img { aspect-ratio: 1/1; object-fit: contain;}
#editProfile form { width: 100%;}
#editProfile form img { width: 100%;}
#editProfile form section { margin-bottom: 20px;}
#editProfile form section h3 { padding: 10px 20px; background: var(--grey); color: var(--white); border: 1px solid var(--grey);}
#editProfile form section .formContentWrapper { padding: 20px; border-left: 1px solid var(--light-grey); border-right: 1px solid var(--light-grey); border-bottom: 1px solid var(--light-grey)}
#editProfile form section ul { display: flex; list-style: none; flex-wrap: wrap; gap: 5px;}
#editProfile form section ul li { flex: 0 0 calc(10% - ((5px * 10) / 9)); position: relative; margin: 0 !important;}
#editProfile form section ul li:hover:after { content: 'X'; background: rgba(255,0,0,.5); line-height: 35px; color: var(--white); font-size: 2em; display: flex; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px; align-items: center; justify-content: center; cursor: pointer;}
#editProfile form section ul li img { width: 100%; aspect-ratio: 1/1; object-fit: cover; display: block;}
.profileFieldNote { font-size: .6em; color: var(--grey); margin-top: 0 !important; font-style: italic;}
#contactVisibility { margin-bottom: 35px;}
#contactVisibilityWrapper { flex: 0 0 100%;}

#editProfile #content { margin-top: 20px;}
label[for="persist"] span { padding-left: 10px;}
.selectContainer { margin: 20px 0; display: flex; gap: 20px; position: relative;}
.selectContainer .selectWrapper { flex: 0 0 calc(33% - (40px / 3));}
.selectContainer .selectWrapper.alignRight { justify-content: end; display: flex; padding-right: 12px; font-size: 1.5em; align-items: center;}
.selectContainer .selectWrapper.alignRight svg { cursor: pointer;}
.selectContainer label { margin-top: 0 !important; color: var(--red) !important; background: var(--white); padding: 0 10px;}
.selectContainer fieldset { border-color: var(--red) !important;}
.selectContainer input[type="text"] { padding: 0.25rem !important; height: 2.438em !important;}
/* .MuiMenu-list li.Mui-selected { background: transparent !important;} */
input[disabled] { color: var(--light-grey);}

.buttonContainer { display: flex; gap: 20px; position: relative; margin-bottom: 20px;}
.buttonContainer button { flex: 0 0 calc(33% - (40px / 3));}

input[type="text"],
input[type="number"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="url"],
button,
textarea { font-family: 'Fira Sans', sans-serif; font-size: 22px; padding: 0.25rem;}

form label { font-weight: 700; font-size: 18px; margin-bottom: 5px;}
form input:not([type=checkbox]) { height: 50px; margin-bottom: 10px;}
form input, form textarea { background: #f7f7f7; border: 1px solid #c4c4c4; border-radius: 0; box-shadow: none; color: #787878; font-weight: 300; width: 100%; padding-right: 60px;}
form input:focus, form textarea:focus { background: var(--white); border: 1px solid #919191; box-shadow: none; outline: none;}
form input:focus-visible, form textarea:focus-visible { background: var(--white); border: 1px solid #919191; box-shadow: none; outline: none;}
form .inputWrapper { position: relative;}
.packageNote { font-size: .8em; color: var(--grey); margin-top: 0 !important; font-style: italic; position: relative; top: -5px;}
form .valid, form .invalid { position: absolute; right: 0; top: 0; height: 50px; width: 50px; text-align: center; line-height: 48px;}
form input.error, form textarea.error { border: 1px solid var(--red);}
form span.required { color: var(--red);}
p.required { font-size: .8em; text-align: right; font-style: italic; width: 100%;}
p.required span { color: var(--red);}
p.errmsg svg { position: relative; top: 4px;}
form input#persist { width: 20px;}
#skip-payment-check { 
  width: fit-content;
  margin-left: 8px;
}
.adminForm article { margin: 0; width:100%;}

form select { background: #f7f7f7; border: 1px solid #c4c4c4; border-radius: 0; width: 100%; height: 50px; line-height: 50px; color: #787878; font-weight: 300; font-size: 22px; font-family: var(--primary-font); padding-left: 10px;}

label, button { margin-top: 1rem;}
button { padding: 0.5rem; background: var(--blue); border: none; color: var(--white);}
button:hover:not(:disabled) { background: var(--grey);}
button:disabled { opacity: .5; cursor: not-allowed;}

textarea { resize: vertical;}

span.charCount { text-align: right; font-size: .7em; color: var(--light-grey); display: inline-block; width: 100%; position: relative; top: -5px;}

form .agreements { padding: 35px 0; margin: 35px 0; border-top: 1px solid var(--light-grey); border-bottom: 1px solid var(--light-grey);}
form .options { padding-bottom: 35px; margin-bottom: 35px; border-bottom: 1px solid var(--light-grey);}
form .options.pro { padding-top: 35px; margin-top: 35px; border-top: 1px solid var(--light-grey);}

.categorySelect { margin-bottom: 20px;}
.categorySelect label { margin-bottom: 0 !important; margin-top: 0 !important;}
.categorySelect .MuiBox-root { column-count: 2;}

#invite { margin-top: 50px;}

span.overrideCallCap { color: var(--light-grey); font-size: .8em; position: relative; top: -10px; cursor: pointer;}
span.overrideCallCap:hover { color: var(--red);}

/* External Video */
.externalVideoWrapper { display: flex;}
.externalVideoWrapper .inputWrapper { flex: 1;}
.externalVideoWrapper select { flex: 0 0 185px; border-right: 0;}
.externalVideoWrapper input { border-left: 0;}

/* Dashboard Items */
.dashboardItemWrapper { display: flex; gap: 20px; flex-wrap: wrap; margin: 20px 0;}
/* .dashboardItemWrapper .dashboardItem { padding: 20px; text-align: center; border-radius: 5px; flex: 0 0 calc((100% - 40px) / 3); position: relative;} */
.dashboardItemWrapper .dashboardItem { background: var(--ultra-light-grey); box-shadow: 2px 2px 2px var(--light-grey); padding: 20px; text-align: center; border-radius: 10px; flex: 0 0 calc((100% - 40px) / 3); position: relative;}
.dashboardItemWrapper .dashboardItem#account { flex: 0 0 100%; background: var(--red);}
.dashboardItemWrapper .dashboardItem#account.active { background: green;}
.dashboardItemWrapper .dashboardItem#account h4 { color: var(--white); margin-top: 0;}
.dashboardItemWrapper .dashboardItem a:hover:after { background: rgba(166, 30, 34, .2); border-radius: 10px;}
.dashboardItemWrapper .dashboardItem a:after { content: ''; display: inline-block; position: absolute; top: 0; bottom: 0; left: 0; right: 0; padding: 20px; text-decoration: none;}
.dashboardItemWrapper .dashboardItem a { text-decoration: none;}
.dashboardItemWrapper .dashboardItem h3 { color: var(--blue);}
.dashboardItemWrapper .dashboardItem h4 { color: var(--blue); margin-top: 15px;}
.dashboardItemWrapper .dashboardItem p.description {}
.dashboardItemWrapper .dashboardItem div.count { font-size: 3em; color: var(--red); text-shadow: 2px 2px 2px var(--light-grey);}
/* .dashboardItemWrapper .dashboardItem div.icon { font-size: 3em; color: var(--red); text-shadow: 2px 2px 2px var(--light-grey);} */
.dashboardItemWrapper .dashboardItem div.icon { font-size: 2em; color: var(--blue); text-shadow: 2px 2px 2px var(--light-grey);}
.dashboardItemWrapper .dashboardItem div.icon svg { position: relative; top: 10px;}

.dashboardItemWrapper.stats { background: var(--blue); color: var(--white); gap: 0; box-shadow: 2px 2px 2px var(--light-grey); border-radius: 10px; justify-content: center;}
.dashboardItemWrapper.stats .dashboardItem { background: none; box-shadow: none; flex: 0 0 25%;}
.dashboardItemWrapper.stats .dashboardItem h4 { color: var(--white); font-size: 18px;}
.dashboardItemWrapper.stats .dashboardItem div.icon { color: var(--white);}
.dashboardItemWrapper.stats .dashboardItem div.icon .MuiBadge-badge { background: var(--red) !important;}
.dashboardItemWrapper.stats h3 { flex: 0 0 100%; text-align: center; margin-top: 25px;}
.dashboardItemWrapper.stats .dashboardItem a:hover::after { background: transparent;}

/* Backend Styles */
.backendColumnWrapper { display: flex;}
.backendColumnWrapper .column#menu { flex: 0 0 calc(30% - 20px); margin-right: 20px;}
.backendColumnWrapper .column#content { flex: 0 0 70%;}
/* .backendColumnWrapper .column#content article { width: 100%;}
.backendColumnWrapper .column#content article.inner p { margin: 0 !important;} */
.backendColumnWrapper .column#content .backendList ul li p { margin: 0 !important;}
.backendTitleBar { display: flex; justify-content: space-between; background: var(--blue); color: var(--white); line-height: 60px; padding: 0 20px; align-items: center;}
.backendTitleBar svg { font-size: 1.5em;}

/* Popup forms */
.MuiDialogContent-root section { width: auto; min-width: 500px; margin: 0;}
.MuiDialogContent-root form { width: 100%; margin: 0;}


.forgotPassword { margin-top: 35px;}
.forgotPassword a { font-size: .75em; color: var(--grey); text-decoration: none;}

/* Error Messages */
.instructions { font-size: 0.75rem; color: var(--red); position: relative; bottom: 10px;}
.instructions > svg { margin-right: 0.25rem;}
.offscreen { position: absolute; left: -9999px;}
.hide { display: none;}
.valid { background: limegreen; color: var(--white); margin-left: 0.25rem;}
.invalid { background: var(--red); color: var(--white); margin-left: 0.25rem;}
.errmsg { background-color: var(--red); color: var(--white); font-weight: bold; padding: 0.5rem; margin-bottom: 0.5rem;}
.line { display: inline-block;}

/* Page Editor */
p.slug { color: #bbb; font-size: 12px; font-style: italic; margin-bottom: 35px;}
.ql-toolbar button { margin-top: 0 !important; background: none !important;}
body.editing main { background: var(--ultra-light-grey); }
.tox-tinymce { border-radius: 0 !important;}
.tox-statusbar__branding { display: none !important;}
p.error { color: var(--red); text-transform: capitalize;}
.tox .tox-button { background: var(--blue) !important; color: var(--white) !important; border-color: transparent !important;}
.tox .tox-button:before {box-shadow: none !important;}

/* Page Editor Images */
ul.pageImages { list-style: none; display: flex; gap: 20px; flex-wrap: wrap;}
ul.pageImages li { list-style: none; flex: 0 0 calc(20% - (80px / 5)); cursor: pointer; position: relative;}
ul.pageImages li:hover:after { content: ''; background: rgba(255,0,0,.5);line-height: 35px; color: var(--white); font-size: 2em; display: flex; position: absolute; top: 5px; bottom: 5px; right: 5px; left: 5px; align-items: center; justify-content: center;}
ul.pageImages li img { width: 100%; display: block; height: auto; aspect-ratio: 1; object-fit: cover;}

/* User List */
ul.usersList { list-style: none; position: relative; margin-top: 20px;}
ul.usersList div { line-height: 50px;}
ul.usersList li { border-bottom: 1px solid var(--light-grey); padding: 0 10px; display: flex; justify-content: space-between; font-size: 18px; border-left: 4px solid transparent;}
ul.usersList li.business { align-items: center;}
ul.usersList li.locked { background: repeating-linear-gradient( -55deg, #FFF, #FFF 10px, var(--ultra-light-grey) 10px, var(--ultra-light-grey) 20px);}
ul.usersList li.business .role { top: 0;}
ul.usersList li.key { border-bottom: none; padding: 0 10px; display: flex; justify-content: space-between; font-weight: bold;}
ul.usersList li.key .roleContainer { text-align: center;}
ul.usersList li:not(.key):hover { background: var(--ultra-light-grey);}
ul.usersList .id { margin-right: 20px; flex: 0 0 5%; align-self: center;}
ul.usersList li.business { border-left: 4px solid var(--light-grey);}
ul.usersList li.business.chosen { border-left: 4px solid orange;}
ul.usersList li.business.canceled {border-left: 4px solid var(--red);}
ul.usersList li.business.active { border-left: 4px solid green;}
ul.usersList .name { flex: 0 0 calc(35% - 20px); padding: 5px 0;}
ul.usersList li.business .name { padding: 15px 0; line-height: 1;}
ul.usersList li.business .name span { line-height: 1;}
ul.usersList li.business .name p { line-height: 1; position: relative; top: 0; font-size: .9em; font-style: italic; color: var(--grey);}
ul.usersList .loggedInDate { flex: 0 0 20%;}
ul.usersList .buttons { flex: 0 0 20%; display: flex; justify-content: flex-end;}
ul.usersList .edit, ul.usersList .delete, ul.usersList .accountLocked, ul.usersList .reviewsLocked, ul.usersList .preview { margin-left: 10px;}
ul.usersList .accountLocked.locked span, ul.usersList .reviewsLocked.locked span { color: var(--red); cursor: pointer;}
ul.usersList .accountLocked.unlocked span, ul.usersList .reviewsLocked.unlocked span { color: green; cursor: pointer;}
ul.usersList .roleContainer { flex: 0 0 20%;}
ul.usersList .role { line-height: 1; padding: 5px 10px; border-radius: 20px; font-size: 14px; position: relative; top: 13px; text-align: center;}
ul.usersList .role.super-admin { background: red; color: var(--white);}
ul.usersList .role.admin { background: red; color: var(--white);}
ul.usersList .role.homeowner { background: blue; color: var(--white);}
ul.usersList .role.contractor { background: green; color: var(--white);}
ul.usersList .role.featured { background: orange; color: var(--white);}
ul.usersList .role.priority { background: #FF6000; color: var(--white);}
ul.usersList .role.featured svg { position: relative; margin: 0 5px; top: 2px;}
ul.usersList .edit span { color: var(--grey); cursor: pointer;}
ul.usersList .delete span { color: var(--red); cursor: pointer;}
ul.usersList .preview span { color: var(--blue); cursor: pointer;}

/* Subscription Key */
.subscriptionStatusKey { font-size: .75em; line-height: 1;}
.subscriptionStatusKey li { list-style: none; border-left: 4px solid var(--light-grey); padding-left: 5px;}
.subscriptionStatusKey li.chosen { border-left: 4px solid orange;}
.subscriptionStatusKey li.canceled {border-left: 4px solid var(--red);}
.subscriptionStatusKey li.active { border-left: 4px solid green;}

/* Requested Users List */
ul.requestedUsersList { list-style: none; position: relative; margin-top: 20px;}
ul.requestedUsersList div { line-height: 50px;}
ul.requestedUsersList li { border-bottom: 1px solid var(--light-grey); padding: 0 10px; display: flex; justify-content: space-between; font-size: 18px;}
ul.requestedUsersList li.key { border-bottom: none; padding: 0 10px; display: flex; justify-content: space-between; font-weight: bold;}
ul.requestedUsersList li.key .roleContainer { text-align: center;}
ul.requestedUsersList li:not(.key):hover { background: var(--ultra-light-grey);}
ul.requestedUsersList .id { margin-right: 20px; flex: 0 0 5%;}
ul.requestedUsersList .name { flex: 0 0 calc(25% - 20px);}
ul.requestedUsersList .name p { line-height: 1; position: relative; top: -14px; font-size: .9em; font-style: italic; color: var(--grey);}
ul.requestedUsersList .business { flex: 0 0 35%;}
ul.requestedUsersList .code { flex: 0 0 10%;}
ul.requestedUsersList .date { flex: 0 0 15%;}
ul.requestedUsersList .buttons { flex: 0 0 15%; display: flex; justify-content: flex-end;}
ul.requestedUsersList .delete, ul.requestedUsersList .send, ul.requestedUsersList .view, ul.requestedUsersList .convert { margin-left: 10px;}
ul.requestedUsersList .delete span { color: var(--red); cursor: pointer;}
ul.requestedUsersList .send span { color: var(--blue); cursor: pointer;}
ul.requestedUsersList .view span { color: var(--black); cursor: pointer;}
ul.requestedUsersList .send.sent span { color: green; cursor: default;}
ul.requestedUsersList .convert span { color: orange; cursor: default;}


/* Admin Add Users */
.selectContainer .addUserWrapper { flex: 0 0 calc(33% - (40px / 3)); display: flex; justify-content: flex-end;}
.addUserWrapper button.addUser { background: none; color: var(--red); position: relative; right: 11px; font-size: 2em; padding: 0; top: -10px; cursor: pointer}
.addUserWrapper button.addUser:hover { color: var(--grey);}
form.adminAddUser section { margin-bottom: 20px;}
form.adminAddUser section:first-of-type { margin-top: 20px;}
form.adminAddUser .formContentWrapper { display: flex; gap: 20px; flex-wrap: wrap;}
form.adminAddUser section h3 { font-size: 2em;}
form.adminAddUser  .column { flex: 0 0 calc(50% - 10px);}
form.adminAddUser  .column img { aspect-ratio: 1/1; object-fit: contain; width: 100%; border: 1px solid var(--light-grey);}
form.adminAddUser  .drop-file-input.single .drop-file-input__label svg { top: 0;}
form.adminAddUser .profilePreview { width: 100%;}
form.adminAddUser .profilePreview ul { display: flex; list-style: none; flex-wrap: wrap; gap: 5px;}
form.adminAddUser .profilePreview ul li { flex: 0 0 calc(10% - ((5px * 10) / 9)); position: relative; margin: 0 !important;}
form.adminAddUser .profilePreview ul li:hover:after { content: 'X'; background: rgba(255,0,0,.5); line-height: 35px; color: var(--white); font-size: 2em; display: flex; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px; align-items: center; justify-content: center; cursor: pointer;}
form.adminAddUser .profilePreview ul li img { width: 100%; aspect-ratio: 1/1; object-fit: cover; display: block;}
form.adminAddUser .profilePreview ul li video { width: 100%; aspect-ratio: 1/1; object-fit: cover; display: block;}
form.adminAddUser .drop-file-preview { width: 100%; display: flex; flex-wrap: wrap; gap: 5px;}
form.adminAddUser .drop-file-preview__title { flex: 0 0 100%;}
form.adminAddUser .drop-file-preview__item { flex: 0 0 calc((100% / 3) - 5px);}
form.adminAddUser section.verification button { margin-right: 20px;}

/* Contractor List */
ul.contractorList { list-style: none; display: flex; gap: 20px; overflow: hidden; justify-content: center;}
ul.contractorList li { flex: 0 0 calc(33.33% - (40px / 3)); background: var(--light-grey); height: 200px; position: relative;}
ul.contractorList li a { text-decoration: none; height: 100%; width: 100%; display: inline-block;}
ul.contractorList li .businessInfo { padding: 10px; position: absolute; bottom: 0; left: 0; width: 100%; background: rgba(0,0,0,.5); color: var(--white); display: flex; justify-content: space-between;}
ul.contractorList li .business_name { font-size: .9em;}
ul.contractorList li .business_rating { flex: 0 0 75px; text-align: right;}
ul.contractorList li .businessInfo svg { position: relative; top: 3px;}
ul.contractorList li .cover { width: 100%; height: 100%; overflow: hidden;}
ul.contractorList li .cover img { width: 100%; min-height: 100%; object-fit: cover;}
ul.contractorList li:hover .cover:after { content: ''; background: rgba(0, 0, 0, .5); height: 100%; width: 100%; display: inline-block; position: absolute; top: 0; left: 0;}

/* Priority Listed Contractor (Featured) */
ul.contractorList li.featured {}
ul.contractorList li.featured .businessInfo { border-bottom: 4px solid gold;}
ul.contractorList li.featured .businessInfo svg { fill: gold;}

/* Contractor View */
.contractorHeader h3, .contractorPortfolio h3, .contractorVideos h3, .contractorReviews h3 { text-align: center; text-transform: uppercase; margin-bottom: 20px;}

.contractorContent .entryContent { display: flex; margin-bottom: 50px; justify-content: space-between;}
.contractorContent .contractorDesscription { white-space: break-spaces;}
.contractorContent .contractorContact { flex: 0 0 40%; margin-left: 20px;}
.contractorContent .contractorContact .contactWrapper { background: var(--ultra-light-grey); padding: 20px;}
.contractorContent .contractorContact p { white-space: pre; overflow: hidden; text-overflow: ellipsis;}
.contractorContent .contractorContact p.website { white-space: normal;}
.contractorContent .contractorContact p.website a { display: flex;}
.contractorContent .contractorContact p.website a svg { flex: 0 0 22px;}
.contractorContent .contractorContact p span { margin-left: 32px;}
.contractorContent .contractorContact p.directMessage { cursor: pointer;}
.contractorContent .contractorContact h3 { color: var(--red);}
.contractorContent .contractorContact svg { margin-right: 10px; position: relative; top: 3px; color: var(--red);}

.contractorContent .reviewsRow {
  justify-content: center;
}
.contractorContent .reviewCol {
  background: #f7f7f7;
  border-radius: 3px;
  margin: 1rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  text-align: center;
}
.contractorContent .reviewCol h6 {
  margin: 0;
  font-weight: 700;
}
.contractorContent .reviewCol p {
  margin: 0;
}
.contractorContent .reviewCol .reviewText {
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  height: 123px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  font-size: 0.88rem;
}
#googleReview .headerSection {
  display: flex;
  justify-content: space-between;
}
#googleReview .button-red:hover {
  background: var(--grey);
}
#googleReview h3 {
  display: inline;
}
#googleReview > .content {
  position: relative;
}
#googleReview .all-reviews-link {
  text-align: center;
}
#googleReview .all-reviews-link:hover {
  color: var(--grey);
}
#googleReview .content {
  width: 100%;
}
.contractorContent .reviewsContainer {
  padding-bottom: 1rem;
}
.companyReviewCol {
  justify-content: center;
  background: #f7f7f7;
  border-radius: 3px;
  text-align: center;
  box-shadow: 1px 2px 4px #ccc;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
#googleReview .companyReviewCol p {
  margin-bottom: 0;
  font-weight: 600;
}


.contractorHeader .coverPhotoWrapper { width: 100%; height: 500px; overflow: hidden; position: relative;}
.contractorHeader .coverPhotoWrapper:after { content: ''; position: absolute; width: 100%; height: 200px; left: 0; bottom: 0; background: linear-gradient(transparent, var(--blue))}
.contractorHeader .coverPhotoWrapper img { width: 100%; }

.contractorHeader .headerActionButtons { position: absolute; bottom: 0; right: 0; z-index: 1;}
.contractorHeader .headerActionButtons .actionsContainer { list-style: none;}
.contractorHeader .headerActionButtons .actionsContainer ul { margin-bottom: 0;}
.contractorHeader .headerActionButtons .actionsContainer li { display: inline-block; margin-right: 10px; font-size: 1.5em; color: var(--red);}
.contractorHeader .headerActionButtons .actionsContainer li:hover { color: var(--grey);}

.businessInfo { display: flex; margin-top: -15%; position: relative; z-index: 1; align-items: center;}
.businessInfo .logoWrapper { border-radius: 50%; overflow: hidden; width: 20%; aspect-ratio: 1/1; background: var(--white); border: 1px solid var(--light-grey); margin-left: 35px; display: grid; align-items: center;}
.businessInfo .logoWrapper img { width: 100%;}
.businessInfo .content { margin-left: 35px; color: var(--white);}
.businessInfo .title, .businessInfo .title h1 { color: var(--white); text-shadow: 2px 2px 2px var(--grey); font-size: 1.5rem;}
.businessInfo .specialty {}

.notification { background: var(--red); color: var(--white); padding: 10px 20px; margin-bottom: 35px !important; display: flex; align-items: center;}
.notification a { color: var(--white);}
.notification svg { margin-right: 20px;}

.titleContainer .titleActions { margin-left: 0; list-style: none;}
.titleContainer .titleActions li { margin-left: 10px; display: inline-block; margin-bottom: 0;}
.titleContainer .titleActions li a { color: var(--white);}
.titleContainer .titleActions li a:hover { color: var(--light-grey);}

.contractorPortfolio { padding: 70px 0; background: var(--ultra-light-grey);}
.contractorPortfolio .inner { margin: 0 auto;}

.contractorReviews { padding: 70px 0; background: var(--ultra-light-grey);}
.contractorReviews .inner { margin: 0 auto;}
.reviewContainer { border-bottom: 1px solid var(--light-grey); margin-bottom: 35px; padding-bottom: 35px;}
.publicReviewList li:last-of-type .reviewContainer { border-bottom: none;}
.reviewWrapper { }
.reviewContainer .review {}
.reviewContainer .review .ratingsWrapper { text-align: center; flex: 0 0 100%;}
.reviewContainer .reviewAvatar {}
.reviewContainer .reviewQuotes { flex: 0 0 50px; margin-right: 20px; margin-top: 15px;}
.reviewContainer .reviewWrapper { display: flex; justify-content: flex-start;}
/* .reviewContainer .reviewWrapper .reviewContent { padding-right: 30%;} */
.reviewContainer .reviewWrapper .reviewContent h5 {}
.reviewContainer .reviewWrapper .reviewContent .reviewText {}
.reviewContainer .reviewWrapper .reviewContent .reviewedBy { text-align: right;}
.reviewContainer .responseWrapper { display: flex; justify-content: flex-end; padding-left: 30%;}
.reviewContainer .responseWrapper .responseIcon { color: var(--red);}
.reviewContainer .responseWrapper .responseIcon svg { font-size: 2em; margin-right: 20px; margin-top: 15px;}
.reviewContainer .responseWrapper .response {}
.reviewContainer .responseWrapper .response .replyText {}
.reviewContainer .responseWrapper .response .respondedBy {}

.contractorVideos { padding: 0 0 70px 0;}
.contractorVideos .inner { margin: 0 auto;}
.contractorVideos .inner .videoContainer { display: flex; gap: 20px; justify-content: center; flex-wrap: wrap;}
.contractorVideos .inner .videoContainer li { list-style: none; flex: 0 0 calc((100% / 3) - (40px / 3));}
.contractorVideos .inner .videoContainer .video video { width: 100%;}
.contractorVideos .inner .videoContainer .video.youtube { aspect-ratio: 16/9;}
.contractorVideos .inner .videoContainer .video.vimeo { aspect-ratio: 16/9;}
.contractorVideos .inner .videoContainer .video.facebook { aspect-ratio: 16/9;}
.contractorVideos .inner .videoContainer .video.youtube > div { height: 100% !important;}
.contractorVideos .inner .videoContainer .video.vimeo > div { height: 100% !important;}
.contractorVideos .inner .videoContainer .video.facebook > div { height: 100% !important;}
#businessVideos .profilePreview li {flex: 0 0 calc((100% / 3) - (10px / 3));}
#businessVideos video { width: 100%; display: block;}

.iyhBorderBox { border: 1px solid var(--light-grey); box-shadow: 2px 2px 2px var(--light-grey); padding: 20px; margin-bottom: 50px; display: flex;}
.iyhBorderBox .icon { margin-right: 20px; flex: 0 0 75px;}
.iyhBorderBox .content { white-space: break-spaces;}
.iyhBorderBox#promotions .content { flex: 0 0 100%;}
.iyhBorderBox#promotions .content h3 { margin-bottom: 20px;}
.iyhBorderBox svg .cls-1 { fill: var(--blue);}

.centeredNote { text-align: center;}

ul.contractorTabs { list-style: none; display: flex; gap: 40px; flex-direction: row; justify-content: start; cursor: pointer; border-top: 1px solid var(--light-grey); border-bottom: 1px solid var(--light-grey); margin-top: 35px;}
/* ul.contractorTabs { list-style: none; display: flex; gap: 20px; flex-direction: row; justify-content: end; position: relative; top: -50px; z-index: 2; cursor: pointer;} */
ul.contractorTabs li a { text-decoration: none;}
ul.contractorTabs li { border-bottom: 2px solid transparent; line-height: 2;}
ul.contractorTabs li:hover { border-bottom: 2px solid var(--blue);}

/* Mail Notification Note */
.mailNote { padding: 20px; margin-top: 20px; border: 1px solid var(--red); color: var(--red);}
.mailNote svg { fill: var(--red); position: relative; top: 4px; margin-right: 20px;}

/* Magazine Block */
section.magazine { background: var(--red); color: var(--white); padding: 35px 0;}
section.magazine h1, section.magazine h2, section.magazine h3, section.magazine h4 { color: var(--white); text-align: center;}

/* Contractor Category Block */
section.contractorCategoryBlock { background: var(--black); color: var(--white); padding: 35px 0;}
section.contractorCategoryBlock p { text-align: center;}
section.contractorCategoryBlock h1, section.contractorCategoryBlock h2, section.contractorCategoryBlock h3, section.contractorCategoryBlock h4 { color: var(--white); text-align: center; margin-bottom: 20px;}

/* Featured Contrctor */
article.spotlight h2 { text-align: center;}
.featuredContractor { margin: 70px 0;}
.featuredContractor .wrapper {}
.featuredContractor a { text-decoration: none; display: flex;}
.featuredContractor .contentWrapper { flex: 0 0 60%; position: relative; left: -10%; display: flex; align-items: center;}
.featuredContractor .content { background: var(--blue); color: var(--white); padding: 40px;}
.featuredContractor .title { white-space: pre-line;}
.featuredContractor .title h4 { color: var(--white); font-size: 1.3em; font-weight: normal;}
.featuredContractor .title h4 strong { font-weight: bold;}
.featuredContractor .bio { margin-top: 10px; font-size: .8em; white-space: pre-line;}
.featuredContractor .phone { font-size: .9em; white-space: pre-line;}
.featuredContractor .specialty { font-size: .9em; white-space: pre-line;}
.featuredContractor .location { font-size: .9em; white-space: pre-line;}
.featuredContractor .cover { flex: 0 0 50%;}
.featuredContractor .cover img { width: 100%; height: 400px; object-fit: cover; display: block;}

.featuredContractor p.center { text-align: center;}

/* Pagination */
.pagination { height: 50px; text-align: center; margin: 50px 0;}
.pagination .bullet { width: 15px; height: 15px; border-radius: 50%; background: var(--ultra-light-grey); display: inline-block; border: 1px solid var(--grey); cursor: pointer;
  margin: 0 7px;}
.pagination .bullet:hover { background: var(--blue);}
.pagination .bullet.active { background: var(--red);}

/* Social Links */
.socialContainer { margin-top: 20px;}
.socialContainer h3 { margin-bottom: 20px;}
.socialContainer ul { list-style: none; margin: 0px; margin-left: 0;}
.socialContainer ul li { display: inline-block; margin-right:  10px; font-size: 30px; color: var(--red); margin-bottom: 0 !important;}
.socialContainer ul li a { color: var(--red);}
.socialContainer ul li a:hover { color: var(--grey);}
.socialContainer ul li:hover { color: var(--grey);}

/* Jobs List */
ul.jobsList { list-style: none; position: relative; margin-top: 20px; margin-bottom: 70px;}
ul.jobsList div { display: inline-block; line-height: 50px;}
ul.jobsList li { border-bottom: 1px solid var(--light-grey); padding: 0 10px; display: flex; justify-content: space-between; font-size: 18px;}
ul.jobsList li.key { border-bottom: none; padding: 0 10px; display: flex; justify-content: space-between; font-weight: bold;}
ul.jobsList li:not(.key):hover { background: var(--ultra-light-grey);}
ul.jobsList .name { flex: 0 0 35%;}
ul.jobsList .user { margin-right: 20px; flex: 0 0 calc(20% - 20px); text-align: center;}
ul.jobsList .homeowner { flex: 0 0 35%;}
ul.jobsList .date { flex: 0 0 25%;}
ul.jobsList .buttons {flex: 0 0 5%; display: flex; justify-content: flex-end;}
ul.jobsList .edit, ul.jobsList .delete, ul.jobsList .share, ul.jobsList .view, ul.jobsList .archive, ul.jobsList .restore { margin-left: 10px;}
ul.jobsList div span {}
ul.jobsList .edit span { color: var(--grey); cursor: pointer;}
ul.jobsList .delete span { color: var(--red); cursor: pointer;}
ul.jobsList .share span { color: var(--blue); cursor: pointer;}
ul.jobsList .view span { color: var(--blue); cursor: pointer;}
ul.jobsList .archive span { color: var(--grey); cursor: pointer;}
ul.jobsList .restore span { color: var(--grey); cursor: pointer;}
ul.jobsList.shared .name { flex: 0 0 30%;}
ul.jobsList.shared .client { flex: 0 0 30%;}
ul.jobsList.shared .date { flex: 0 0 13%;}
ul.jobsList.shared .buttons { flex: 0 0 5%;}
ul.jobsList.shared .statusContainer { flex: 0 0 15%;}
ul.jobsList .status { line-height: 1; padding: 5px 10px; border-radius: 20px; font-size: 14px; text-transform: uppercase; width: 100%; text-align: center;}
ul.jobsList .status.pending { background: orange; color: var(--white);}
ul.jobsList .status.complete { background: black; color: var(--white);}
ul.jobsList .status.archived { background: #FF6000; color: var(--white);}
ul.jobsList .status.viewed { background: cadetblue; color: var(--white);}
ul.jobsList .status.progress { background: red; color: var(--white); height: auto; margin-top: 0;}
ul.jobsList .status.replied { background: blue; color: var(--white);}
ul.jobsList .status.new { background: green; color: var(--white);}
.selectContainer .selectWrapper button { width: 100%; margin-top: 0;}

/* Job Image List */
ul.jobImageList { display: flex; list-style: none; width: calc(100% + 10px); margin-left: -5px;}
ul.jobImageList li { width: 10%; position: relative; padding: 5px;}
.MuiDialog-root:not(#viewJobPopup) ul.jobImageList li:hover:after { content: 'X'; background: rgba(255,0,0,.5);line-height: 35px; color: var(--white); font-size: 2em; display: flex; position: absolute; top: 5px; bottom: 5px; right: 5px; left: 5px; align-items: center; justify-content: center;}
ul.jobImageList li img { width: 100%; display: block; height: auto; aspect-ratio: 1; object-fit: cover;}

/* Create Jobs */
form.createProject { width: 100%;}
form.createProject .drop-file-input.embed { margin-top: 20px;}
form.createProject button.clearAddress { font-weight: normal; font-size: 14px; background: var(--grey); padding: 2px 10px; color: var(--white); margin: 0 0 0 10px; cursor: pointer;}
form.createProject .servicesContainer .serviceWrapper { flex: 0 0 100%;}
#alert-dialog-description .serviceWrapper { flex: 0 0 100%;}
form.createProject .selectedCategories { margin-top: 1rem;}
form.createProject ul.marketingCategories { margin-top: 0; margin-bottom: 10px;}
/* form.createProject ul.marketingCategories li:hover { background: var(--red); cursor: default;} */

/* Edit Jobs Popup */
#editJobPopup .valid { background: none; color: transparent;}
#editJobPopup img { background: none; color: transparent;}
.createJob { margin-left: 11px;}

/* Edit Promotion Popup */
#promotionsEditPopup img { width: 100%; display: block;}
#promotionsEditPopup .promotionalImageWrapper { position: relative;}
#promotionsEditPopup .promotionalImageWrapper:hover:after { content: 'X'; background: rgba(255,0,0,.5);line-height: 35px; color: var(--white); font-size: 2em; display: flex; position: absolute; top: 5px; bottom: 5px; right: 5px; left: 5px; align-items: center; justify-content: center; cursor: pointer;}
#promotionsEditPopup .promotionalImageWrapper.noImage:hover:after { display: none;}

/* Zip Code List */
ul.zipcodeList { list-style: none; position: relative; margin-top: 35px; margin-bottom: 70px; display: flex; flex-wrap: wrap; gap: 20px;}
ul.zipcodeList div { display: inline-block; line-height: 50px;}
ul.zipcodeList li { background: var(--ultra-light-grey); border-radius: 5px; width: 100px; text-align: center;}
ul.zipcodeList li:hover { background: var(--grey); cursor: pointer; color: var(--white)}
ul.zipcodeList .zip {}
.selectContainer .addZipWrapper { flex: 0 0 calc(33% - (40px / 3)); display: flex; justify-content: flex-end;}
.addZipWrapper button.addZip { background: none; color: var(--red); position: relative; right: 11px; font-size: 2em; padding: 0; top: -10px; cursor: pointer}

/* Coupon List */
ul.couponList { list-style: none; position: relative; margin-top: 20px; margin-bottom: 70px;}
ul.couponList div { display: inline-block; line-height: 50px;}
ul.couponList li { border-bottom: 1px solid var(--light-grey); padding: 0 10px; display:flex; justify-content: space-between; font-size: 18px;}
ul.couponList li.key { border-bottom: none; padding: 0 10px; display:flex; justify-content: space-between; font-weight: bold;}
ul.couponList li.key .statusContainer { text-align: center;}
ul.couponList li:not(.key):hover { background: var(--ultra-light-grey);}
ul.couponList .name { flex: 0 0 40%;}
ul.couponList .code { flex: 0 0 30%;}
ul.couponList .statusContainer { flex: 0 0 10%; text-align: center;}
ul.couponList .statusContainer .status { line-height: 1; padding: 5px 10px; border-radius: 20px; font-size: 14px; position: relative; text-align: center; text-transform: uppercase;}
ul.couponList .statusContainer .status.active { background: green; color: var(--white);}
ul.couponList .statusContainer .status.disabled { background: red; color: var(--white);}
ul.couponList .buttons { flex: 0 0 20%; display: flex; justify-content: flex-end;}
ul.couponList .view, ul.couponList .delete, ul.couponList .edit {  margin-left: 10px;}
ul.couponList div span {}
ul.couponList .view span { color: var(--grey); cursor: pointer;}
ul.couponList .delete span { color: var(--red); cursor: pointer;}
ul.couponList .edit span { color: var(--grey); cursor: pointer;}

/* Package List */
ul.packageList { list-style: none; position: relative; margin-top: 20px; margin-bottom: 70px;}
ul.packageList div { display: inline-block; line-height: 50px;}
ul.packageList li { border-bottom: 1px solid var(--light-grey); padding: 0 10px; display:flex; justify-content: space-between; font-size: 18px;}
ul.packageList li.key { border-bottom: none; padding: 0 10px; display:flex; justify-content: space-between; font-weight: bold;}
ul.packageList li.key .statusContainer { text-align: center;}
ul.packageList li:not(.key):hover { background: var(--ultra-light-grey);}
ul.packageList .name { flex: 0 0 55%;}
ul.packageList .price { flex: 0 0 15%;}
ul.packageList .type { flex: 0 0 10%;}
ul.packageList .statusContainer { flex: 0 0 10%; text-align: center;}
ul.packageList .statusContainer .status { line-height: 1; padding: 5px 10px; border-radius: 20px; font-size: 14px; position: relative; text-align: center; text-transform: uppercase;}
ul.packageList .statusContainer .status.active { background: green; color: var(--white);}
ul.packageList .statusContainer .status.disabled { background: red; color: var(--white);}
ul.packageList .buttons { flex: 0 0 10%; display: flex; justify-content: flex-end;}
ul.packageList .view, ul.packageList .delete, ul.packageList .edit {  margin-left: 10px;}
ul.packageList div span {}
ul.packageList .view span { color: var(--grey); cursor: pointer;}
ul.packageList .delete span { color: var(--red); cursor: pointer;}
ul.packageList .edit span { color: var(--grey); cursor: pointer;}
form#createPackage { width: 100%;}

/* Available Packages */
ul.availablePackages { list-style: none; margin-top: 21px; margin-bottom: 70px; display: flex; gap: 20px; flex-wrap: wrap; justify-content: center;}
ul.availablePackages li:not(.cancel) { border: 1px solid var(--light-grey); flex: 0 0 calc(50% - 10px); padding: 50px; text-align: center; cursor: pointer;}
ul.availablePackages li.active { border: 1px solid var(--red); position: relative; padding: 50px 50px 100px 50px;}
ul.availablePackages li.active .configureActivePackage { position: absolute; background: var(--red); color: var(--white); width: 100%; height: 50px; line-height: 50px; bottom: 0; left: 0; display: inline-block;}
ul.availablePackages li.active .configureActivePackage p { padding: 0; margin: 0; position: relative;}
ul.availablePackages li.active .configureActivePackage p svg { position: relative; top: 3px;}
ul.availablePackages li.active .configureActivePackage a { position: absolute; right: 10px; top: 5px; color: var(--white);}
ul.availablePackages li.cancel { flex: 0 0 100%; display: flex; align-items: center; justify-content: center;}
ul.availablePackages li div { flex: 0 0 100%;}
ul.availablePackages li.cancel div { flex: 0 0 calc(50% - 10px); display: flex; align-items: center; justify-content: center; padding: 50px; cursor: pointer; border: 1px solid var(--light-grey);}
ul.availablePackages li.cancel div:hover { border: 1px solid var(--red);}
ul.availablePackages li:hover:not(.cancel) { border: 1px solid var(--red);}
ul.availablePackages li .name {}
ul.availablePackages li .price {}
ul.availablePackages li .description { white-space: pre-line;}
.column.accountConfigure { width: 100%;}
.subscriptionAddonList { list-style: none;}
.subscriptionAddonList p { position: relative; top: -15px; padding-left: 40px;}
ul.availablePackages li.active .configureActivePackage.active { background: green;}


/* Configure Subscription */
.buttonWrapper button { margin-right: 10px;}
.subscriptionNote { font-size: .8em; color: var(--red); border: 1px solid var(--red); padding: 5px 25px;}
select#active_state { margin-top: 35px;}

  /* Mobile Subscription Steps */
.mobileProgress { text-align: center; font-size: 1.5em;}


/* Subscription Details */
.subDetails { border: 1px solid var(--red); padding: 20px;}
.subDetails ul { list-style: none; position: relative; margin-top: 5px; margin-bottom: 20px; display: flex; flex-wrap: wrap; gap: 15px;}
.subDetails ul li { background: var(--red); color: var(--white); border-radius: 5px; width: 100px; text-align: center; margin-bottom: 0 !important;}
.subDetails ul.cat li { width: auto !important; padding: 0 10px;}
.subDetails ul li div { display: inline-block; line-height: 50px;}
.subDetails div.activeState { background: var(--red); color: var(--white); border-radius: 5px; width: auto; text-align: center; padding: 0 10px; line-height: 50px; display: inline-block; margin-bottom: 20px;}

/* Billing */
#charges { background: var(--red); color: var(--white); padding: 10px;}
.billingColumnWrapper { display: flex; flex-wrap: wrap; gap: 20px;}
.billingColumn { flex: 0 0 calc((100% / 3) - (40px / 3));}
.nameColumnWrapper { display: flex; flex-wrap: wrap; gap: 20px;}
.nameColumn { flex: 0 0 calc((100% / 2) - 10px);}
.paymentColumnWrapper { display: flex; flex-wrap: wrap; gap: 20px;}
.paymentColumnWrapper #cc { flex: 0 0 calc(48% - 60px);}
.paymentColumnWrapper #cc input { padding-left: 30px;}
.paymentColumnWrapper #cc input { color: black;}
.paymentColumnWrapper #ex-month { flex: 0 0 15%;}
.paymentColumnWrapper #ex-month input { color: black;}
.paymentColumnWrapper #ex-year { flex: 0 0 17%;}
.paymentColumnWrapper #ex-year input { color: black;}
.paymentColumnWrapper #sc { flex: 0 0 20%;}
.paymentColumnWrapper #sc input { padding-left: 30px;}
.paymentColumnWrapper #sc input { color: black;}
.couponColumnWrapper { display: flex; flex-wrap: wrap; gap: 20px;}
.couponColumnWrapper button { position: relative; top: 5px; cursor: pointer;}
.couponColumnWrapper button.verified { background: green !important; cursor: default;}
hr { margin: 20px 0;}
em.chargeNote { font-size: .7em; position: relative; top: -15px;}
p.initialPayment { padding: 20px; background: var(--red); color: var(--white);}
div.couponDetails { padding: 10px 20px; background: var(--light-grey);}
div.paymentDetails { padding: 10px 20px; background: var(--ultra-light-grey);}
div.fees { border: 1px solid var(--red); margin-top: 10px;}
span.viewCC { position: absolute; left: 0; top: 0; line-height: 50px; height: 50px; width: 30px; text-align: center;}
span.viewCC svg { top: 2px; position: relative;}

/* Marketing Lists */
ul.marketingZipcodes { list-style: none; position: relative; margin-top: 35px; margin-bottom: 70px; display: flex; flex-wrap: wrap; gap: 15px;}
ul.marketingZipcodes div { display: inline-block; line-height: 50px;}
ul.marketingZipcodes li { background: var(--ultra-light-grey); border-radius: 5px; width: 100px; text-align: center; margin-bottom: 0 !important;}
ul.marketingZipcodes li:hover { background: var(--grey); cursor: pointer; color: var(--white)}
ul.marketingZipcodes li.active { background: var(--red); cursor: pointer; color: var(--white)}

ul.marketingCounties { list-style: none; position: relative; margin-top: 35px; margin-bottom: 70px; display: flex; flex-wrap: wrap; gap: 15px;}
ul.marketingCounties div { display: inline-block; line-height: 50px;}
ul.marketingCounties li { background: var(--ultra-light-grey); border-radius: 5px; padding: 0 15px; text-align: center; margin-bottom: 0 !important;}
ul.marketingCounties li:hover { background: var(--grey); cursor: pointer; color: var(--white)}
ul.marketingCounties li.active { background: var(--red); cursor: pointer; color: var(--white)}

ul.marketingCategories { list-style: none; position: relative; margin-top: 35px; margin-bottom: 70px; display: flex; flex-wrap: wrap; gap: 15px;}
ul.marketingCategories div { display: inline-block; line-height: 50px;}
ul.marketingCategories li { background: var(--ultra-light-grey); border-radius: 5px; padding: 0 15px; text-align: center; margin-bottom: 0 !important;}
ul.marketingCategories li:hover { background: var(--grey); cursor: pointer; color: var(--white)}
ul.marketingCategories li.active { background: var(--red); cursor: pointer; color: var(--white)}

.servicesContainer { }
.servicesContainer .serviceContent {}
.servicesContainer .serviceContainer { display: flex; flex-wrap: wrap; margin-top: 35px; justify-content: space-between;}
.servicesContainer .serviceWrapper { flex: 0 0 68%;}
.servicesContainer .selectedCategories { flex: 0 0 30%;}
.servicesContainer .selectedCategories li { background: var(--red); cursor: pointer; color: var(--white)}

/* Subscription Overview */
.subscriptionOverview {}
.subscriptionOverview div { display: inline-block; line-height: 50px;}
.subscriptionOverview ul { list-style: none; position: relative; margin-top: 15px; margin-bottom: 35px; display: flex; flex-wrap: wrap; gap: 15px;}
.subscriptionOverview ul li { background: var(--ultra-light-grey); border-radius: 5px; padding: 0 15px; text-align: center; margin-bottom: 0 !important;}

/* Subscription Popup */
section#subscriptionDetails span.status { background: var(--light-grey); color: var(--white); border-radius: 10px; padding: 5px 10px 2px 10px; text-transform: uppercase;}
section#subscriptionDetails span.status.active { background: green;}
section#subscriptionDetails span.status.active:hover { cursor: pointer; background: var(--grey);}
section#subscriptionDetails span.status.active svg { position: relative; top: -2px; margin-left: 5px;}
section#subscriptionDetails span.status.canceled { background: var(--red);}
section#subscriptionDetails span.status.chosen { background: orange;}

#editSubscriptionPopup .css-1869usk-MuiFormControl-root { margin: 0 0 20px 0 !important;}
#editSubscriptionPopup li { background: var(--red); padding: 5px 10px 3px 10px; color: var(--white);}
#editSubscriptionPopup li.addItem { background: transparent; padding: 0; color: var(--red); display: flex; align-items: center;}
#editSubscriptionPopup li.addItem:hover { background: transparent; color: var(--grey);}
#editSubscriptionPopup li:hover { background: var(--grey);}

#editSubscriptionPopup ul.marketingCategories { margin-top: 0px; margin-bottom: 35px;}
#editSubscriptionPopup ul.marketingCounties { margin-top: 0px; margin-bottom: 35px;}
#editSubscriptionPopup ul.marketingZipcodes { margin-top: 0px; margin-bottom: 35px;}


/* Progress Bar */
.progress-container { width: 100%; position: relative; margin-top: 35px;}
.barmarker-container { display: flex; flex-flow: row nowrap; justify-content: space-around;}
.barmarker { width: 100%; display: grid; place-content: center;}
.barmarker:is(:not(:last-child)) { border-right: 1px solid #000; }
.progress { -webkit-appearance: none; appearance: none; width: 100%; height: 32px; margin-top: 5px;}
/* For Chrome or Safari */
progress::-webkit-progress-bar { background-color: var(--ultra-light-grey);}
progress::-webkit-progress-value { background-color: var(--red) !important;}
/* For Firefox */
progress { background-color: var(--ultra-light-grey);}
progress::-moz-progress-bar { background-color: var(--red) !important;}
.configureForm { width: 100%; margin-top: 35px; border: 1px solid var(--light-grey); padding: 35px;}
.configureForm .remove-button { display: none;}
.configureForm .buttonWrapper { text-align: right;}
.configureForm .buttonWrapper button { margin-left: 10px; margin-right: 0;}
.configureForm .ccError { background: var(--red); color: var(--white); padding: 20px;}
.configureForm .ccError svg { position: relative; top: 3px;}

/* Service Category List */
ul.serviceCatList { list-style: none; position: relative; margin-top: 35px; margin-bottom: 0px;}
ul.serviceCatList div { display: inline-block; line-height: 50px;}
ul.serviceCatList li { border-bottom: 1px solid var(--light-grey); padding: 0 10px; display: flex; justify-content: space-between;}
ul.serviceCatList li.key { border-bottom: none; padding: 0 10px; display: flex; justify-content: space-between; font-weight: bold;}
ul.serviceCatList li:not(.key):hover { background: var(--ultra-light-grey);}
ul.serviceCatList .name { flex: 0 0 50%;}
ul.serviceCatList .buttons { flex: 0 0 50%; display: flex; justify-content: flex-end;}
ul.serviceCatList .view, ul.serviceCatList .delete, ul.serviceCatList .edit { margin-left: 10px;}
ul.serviceCatList div span {}
ul.serviceCatList .view span { color: var(--grey); cursor: pointer;}
ul.serviceCatList .delete span { color: var(--red); cursor: pointer;}
ul.serviceCatList .edit span { color: var(--grey); cursor: pointer;}

/* Markets List */
ul.marketsList { list-style: none; position: relative; margin-top: 20px; margin-bottom: 0px;}
ul.marketsList div { display: inline-block; line-height: 50px;}
ul.marketsList li { border-bottom: 1px solid var(--light-grey); padding: 0 10px; display: flex; justify-content: space-between; font-size: 18px;}
ul.marketsList li.key { border-bottom: none; font-weight: bold; display: flex; justify-content: space-between; font-size: 18px;}
ul.marketsList li:not(.key):hover { background: var(--ultra-light-grey);}
ul.marketsList .name { flex: 0 0 40%;}
ul.marketsList .state { flex: 0 0 15%;}
ul.marketsList .date { flex: 0 0 30%;}
ul.marketsList .buttons { flex: 0 0 15%; display: flex; justify-content: flex-end;}
ul.marketsList .view, ul.marketsList .delete, ul.marketsList .edit { margin-left: 10px;}
ul.marketsList div span {}
ul.marketsList .view span { color: var(--grey); cursor: pointer;}
ul.marketsList .delete span { color: var(--red); cursor: pointer;}
ul.marketsList .edit span { color: var(--grey); cursor: pointer;}

/* Ticket List */
ul.ticketList { list-style: none; position: relative; margin-top: 20px;}
ul.ticketList div { line-height: 50px;}
ul.ticketList li { border-bottom: 1px solid var(--light-grey); padding: 0 10px; display: flex; justify-content: space-between; font-size: 18px; align-items: center;}
ul.ticketList li.locked { background: repeating-linear-gradient( -55deg, #FFF, #FFF 10px, var(--ultra-light-grey) 10px, var(--ultra-light-grey) 20px);}
ul.ticketList li.key { border-bottom: none; padding: 0 10px; display: flex; justify-content: space-between; font-weight: bold;}
ul.ticketList li:not(.key):hover { background: var(--ultra-light-grey);}
ul.ticketList .subject { flex: 0 0 40%;}
ul.ticketList .date { flex: 0 0 calc(40% - 20px);}
ul.ticketList.admin .user { flex: 0 0 20%;}
ul.ticketList.admin .user p { line-height: 1; position: relative; top: -14px; font-size: .9em; font-style: italic; color: var(--grey);}
ul.ticketList.admin .subject { flex: 0 0 25%;}
ul.ticketList.admin .type { flex: 0 0 12%; line-height: 1;}
ul.ticketList.admin .statusContainer { flex: 0 0 13%; text-align: center;}
ul.ticketList.admin .statusContainer .status { line-height: 1; padding: 5px 10px; border-radius: 20px; font-size: 14px; position: relative; text-transform: uppercase;}
ul.ticketList.admin .status.unread { background: red; color: var(--white);}
ul.ticketList.admin .status.read { background: black; color: var(--white);}
ul.ticketList.admin .status.replied { background: orange; color: var(--white);}
ul.ticketList.admin .date { flex: 0 0 calc(25% - 20px); padding-left: 2%;}
ul.ticketList .buttons { flex: 0 0 10%; display: flex; justify-content: flex-end;}
ul.ticketList .view, ul.ticketList .delete, ul.ticketList .edit { margin-left: 10px;}
ul.ticketList div span {}
ul.ticketList .view span { color: var(--grey); cursor: pointer;}
ul.ticketList .delete span { color: var(--red); cursor: pointer;}
ul.ticketList .edit span { color: var(--grey); cursor: pointer;}

/* Ticket Popup */
.viewTicketPopup p { line-height: 30px;}
.viewTicketPopup span.status { line-height: 1; padding: 5px 10px; border-radius: 20px; font-size: 14px; position: relative; text-transform: uppercase; background: var(--black); color: var(--white);}
.viewTicketPopup span.status.unread { background: red; color: var(--white);}
.viewTicketPopup span.status.unread { background: black; color: var(--white);}
.viewTicketPopup .ticket-action-buttons button {
  margin: 10px
}

form#supportForm { width: 100%;}

/* States List */
ul.statesList { list-style: none; position: relative; margin-top: 35px; margin-bottom: 70px; column-count: 2;}
ul.statesList div { display: inline-block; line-height: 50px;}
ul.statesList li { border-bottom: 1px solid var(--light-grey); padding: 0 10px;}
ul.statesList li:hover { background: var(--ultra-light-grey);}
ul.statesList .state { width: 50%;}
ul.statesList div span {}
ul.statesList .status { margin-left: 20px; line-height: 1; padding: 5px 10px; border-radius: 20px; font-size: 14px; position: relative; top: 13px; background: red; color: var(--white); float: right;}
ul.statesList .status.active { background: green; color: var(--white);}

/* View Market Popup */
.viewMarketPopup h4 { margin-top: 20px;}

/* Messages List */
ul.messagesList { list-style: none; position: relative; margin-top: 35px; margin-bottom: 70px;}
ul.messagesList div { display: inline-block; line-height: 50px;}
ul.messagesList li { border-bottom: 1px solid var(--light-grey); padding: 0 10px;}
ul.messagesList li:hover { background: var(--ultra-light-grey);}
ul.messagesList .receiver { width: 50%;}
ul.messagesList .view, ul.messagesList .delete { float: right; margin-left: 10px;}
ul.messagesList div span {}
ul.messagesList .view span { color: var(--grey); cursor: pointer;}
ul.messagesList .delete span { color: var(--red); cursor: pointer;}

/* Threaded Chat */
ul.chat { height: calc(500px - 40px); width: 100%;}
ul.chat .timestamp { font-size: .7em; color: var(--light-grey);}
ul.chat li { list-style: none; display: flex; margin-bottom: 10px !important;}
ul.chat .sent { justify-content: flex-end;}
ul.chat .received { justify-content: flex-start;}
ul.chat .sent .message { text-align: right;}
ul.chat .message { flex: 0 0 70%; min-width: 200px;}
ul.chat .bubble { padding: 10px 15px; border-radius: 20px; font-size: 18px !important;}
ul.chat .sent .bubble { text-align: right; color: var(--white); background: var(--blue);}
ul.chat .received .bubble { text-align: left; color: var(--grey); background: var(--ultra-light-grey);}
.chatPopup input { margin-top: 20px;}
.chatPopup form { padding-bottom: 0;}
.chatPopup MuiDialogContent-root { padding-bottom: 0;}
.MuiTabs-vertical { flex: 0 0 300px !important; border-right: 1px solid var(--light-grey) !important;}
.chatBox { border-top: 1px solid var(--light-grey); border-left: 1px solid var(--light-grey); border-right: 1px solid var(--light-grey); margin-top: 21px; }
.chatBox button:hover:not(:disabled) { background: var(--grey) !important;}
.chatBox button { flex-direction: row !important; justify-content: left !important; font-size: 18px !important;}
.chatSendMessage { width: 100%; position: relative; padding-bottom: 0 !important;}
.chatSendMessage button { position: absolute; top: 0; right: 0; margin-top: 0;}
.chatSendMessage #message { width: 100%; padding-right: 80px;}
.MuiButtonBase-root { text-align: left !important;}
.MuiButtonBase-root.Mui-selected { color: var(--white) !important; background: var(--red) !important; text-align: left !important;}
.MuiTabs-indicator { background-color: var(--red) !important;}
.MuiBadge-badge { background-color: var(--red) !important;}
.chatBox div[role="tabpanel"] { width: 100%; overflow-y: scroll;}
.archiveConversationContainer {}
.archiveConversationContainer span { font-size: .7em; color: var(--grey); cursor: pointer;}
.restoreConversation { list-style: none; display: flex; flex-wrap: wrap;}
.restoreConversation li { flex: 0 0 50%;}
.restoreConversation li label { margin-top: 0 !important;}

/* Favorites List */
ul.favoriteList { list-style: none; position: relative; margin-top: 20px; margin-bottom: 70px;}
ul.favoriteList div { display: inline-block; line-height: 50px;}
ul.favoriteList li { border-bottom: 1px solid var(--light-grey); padding: 0 10px; display: flex; justify-content: space-between; font-size: 18px;}
ul.favoriteList li.key { border-bottom: none; padding: 0 10px; display: flex; justify-content: space-between; font-weight: bold;}
ul.favoriteList li:not(.key):hover { background: var(--ultra-light-grey);}
ul.favoriteList .name { flex: 0 0 40%;}
ul.favoriteList .date { flex: 0 0 40%;}
ul.favoriteList .buttons { flex: 0 0 calc(20% - 20px); display: flex; justify-content: flex-end;}
ul.favoriteList .edit, ul.favoriteList .delete, ul.favoriteList .share {  margin-left: 10px;}
ul.favoriteList div span {}
ul.favoriteList .edit span { color: var(--grey); cursor: pointer;}
ul.favoriteList .delete span { color: var(--red); cursor: pointer;}
ul.favoriteList .share span { color: var(--blue); cursor: pointer;}
.contractors_share_list { margin-top: 35px; column-count: 2;}
.contractors_share_list label { margin: 0 !important;}

/* Promotions List */
ul.promotionsList { list-style: none; position: relative; margin-top: 20px; margin-bottom: 70px;}
ul.promotionsList div { display: inline-block; line-height: 50px;}
ul.promotionsList li { border-bottom: 1px solid var(--light-grey); padding: 0 10px; display: flex; justify-content: space-between; font-size: 18px;}
ul.promotionsList li.key { border-bottom: none; padding: 0 10px; display: flex; justify-content: space-between; font-weight: bold;}
ul.promotionsList li:not(.key):hover { background: var(--ultra-light-grey);}
ul.promotionsList .name { flex: 0 0 50%;}
ul.promotionsList .statusContainer { flex: 0 0 15%;}
ul.promotionsList .date { flex: 0 0 25%;}
ul.promotionsList .buttons { flex: 0 0 calc(10% - 20px); display: flex; justify-content: flex-end;}
ul.promotionsList .status { margin-right: 20px; line-height: 1; padding: 5px 10px; border-radius: 20px; font-size: 14px; text-transform: uppercase;}
ul.promotionsList .status.draft { background: orange; color: var(--white);}
ul.promotionsList .status.archived { background: blue; color: var(--white);}
ul.promotionsList .status.active { background: green; color: var(--white);}
ul.promotionsList .edit, ul.promotionsList .delete, ul.promotionsList .share { margin-left: 10px;}
ul.promotionsList div span {}
ul.promotionsList .edit span { color: var(--grey); cursor: pointer;}
ul.promotionsList .delete span { color: var(--red); cursor: pointer;}
ul.promotionsList .share span { color: var(--blue); cursor: pointer;}

form#createPromotion { width: 100%;}
form#createPromotion #details { margin-bottom: 20px;}
#promotionsEditPopup .promotionalImageWrapper { margin-bottom: 20px;}

/* Admin Promotions */
#promotions .promotionsList {margin-bottom: 0;}

/* Public Promotional List */
ul.promotionalList { list-style: none;}
ul.promotionalList .promotionWrapper { position: relative;}
ul.promotionalList .promotionWrapper img { width: 100%; display: block;}
ul.promotionalList .promotionWrapper .hoverContent { display: none; position: absolute; width: 100%; height: 100%; background: rgba(0,0,0,.5); top: 0; left: 0; cursor: pointer;}
ul.promotionalList .promotionWrapper:hover .hoverContent { display: block; color: var(--white); padding: 70px;}
ul.promotionalList .promotionWrapper .name { font-size: 2em;}
ul.promotionalList .promotionWrapper .details { font-size: var(--primary-font-size);}

/* Reviews List */
ul.reviewList { list-style: none; position: relative; margin-top: 20px; margin-bottom: 70px; font-size: 18px;}
ul.reviewList div { line-height: 50px;}
ul.reviewList li { border-bottom: 1px solid var(--light-grey); padding: 0 10px; display: flex; justify-content: space-between; font-size: 18px;}
ul.reviewList li.key { border-bottom: none; padding: 0 10px; display: flex; justify-content: space-between; font-weight: bold;}
ul.reviewList li:not(.key):hover { background: var(--ultra-light-grey);}
ul.reviewList .name { flex: 0 0 40%;}
ul.reviewList .date { flex: 0 0 calc(25% - 20px);}
ul.reviewList .statusContainer { flex: 0 0 25%;}
ul.reviewList .buttons { flex: 0 0 10%; display: flex; justify-content: flex-end;}
ul.reviewList .status { line-height: 1; padding: 5px 10px; border-radius: 20px; font-size: 14px; position: relative; top: 0px; text-transform: uppercase; background: var(--black); color: var(--white); display: inline-block;}
ul.reviewList .status.pending { background: red; color: var(--white);}
ul.reviewList .status.approved { background: green; color: var(--white);}
ul.reviewList .edit, ul.reviewList .delete, ul.reviewList .share, ul.reviewList .view { margin-left: 10px;}
ul.reviewList div span {}
ul.reviewList .edit span { color: var(--grey); cursor: pointer;}
ul.reviewList .view span { color: var(--grey); cursor: pointer;}
ul.reviewList .delete span { color: var(--red); cursor: pointer;}
ul.reviewList .share span { color: var(--blue); cursor: pointer;}
ul.reviewList.client .name { flex: 0 0 25%; overflow: hidden;}
ul.reviewList.client .rating { flex: 0 0 calc(15% - 35px); margin-right: 35px;}
ul.reviewList.client .rating span { position: relative; top: 1px; font-size: 18px !important;}
.contractorReviewPopup .MuiDialogActions-root .approve { background: rgba(60, 109, 141, .2); color: var(--blue);}
.contractorReviewPopup .MuiDialogActions-root .approve:hover { background: var(--blue); color: white;}
.contractorReviewPopup .MuiDialogActions-root .dispute { background: rgb(255,229,229); color: rgb(205,43,49);}
.contractorReviewPopup .MuiDialogActions-root .dispute:hover { background: rgb(205,43,49); color: white;}
.contractorReviewPopup h4 { margin-top: 20px;}

/* Admin Review Popup */
.viewReviewPopup .MuiDialogActions-root .confirm { color: green; background: rgba(0, 127, 0, .2)}
.viewReviewPopup .MuiDialogActions-root .confirm:hover { color: white; background: rgba(0, 127, 0, 1);}
.viewReviewPopup .MuiDialogActions-root .deny { background: rgb(255,229,229); color: rgb(205,43,49);}
.viewReviewPopup .MuiDialogActions-root .deny:hover { background: rgb(205,43,49); color: white;}

/* Public Reviews */
ul.publicReviewList { list-style: none;}

/* Contractor Portfolio Images */
ul.portfolioImageList { display: flex; list-style: none; width: calc(100% + 10px); margin-left: -5px;}
ul.portfolioImageList li { width: 10%; position: relative; padding: 5px; cursor: pointer;}
ul.portfolioImageList li img { width: 100%; display: block; height: auto; aspect-ratio: 1; object-fit: cover;}

/* Metrics */
ul.metrics { margin-bottom: 20px;}
ul.metrics li { display: flex; align-items: center;}
ul.metrics li.key { font-size: 20px !important; font-weight: bold;}
ul.metrics li.key p.rank { font-size: 20px !important; font-weight: bold;}
ul.metrics li p.rank {font-size: 2em; flex: 0 0 15%; text-align: center;}
ul.metrics li p.name { flex: 0 0 50%;}
ul.metrics li p.count { flex: 0 0 20%; text-align: center;}

/* MUI Overrides */
.MuiPaper-root { min-width: 600px !important;}
.MuiMenu-paper { min-width: auto !important;}
.MuiDialogActions-root { padding:  24px !important;}
.MuiDialogActions-root button { font-size: 16px !important; margin-top: 0; cursor: pointer; border: none !important;}
.MuiDialogActions-root .cancel { background: rgb(238,237,239); color: rgb(111,110,119);}
.MuiDialogActions-root .cancelX { background: none; color: var(--black); position: absolute; top: 0; right: 0;}
.MuiDialogActions-root .cancelX:hover { background: none; color: var(--red);}
.MuiDialogActions-root .cancel:hover { background: rgb(233,232,234);}
.MuiDialogActions-root .confirm { background: rgb(255,229,229); color: rgb(205,43,49)}
.MuiDialogActions-root .confirm:hover { background: rgb(253,216,216);}
.MuiDialogContent-root, #alert-dialog-description { font-size: 16px !important;}
.MuiDialogTitle-root { font-size: 1em !important;}
.MuiLinearProgress-root { position: absolute !important; bottom: 0 !important; left: 0 !important; width: 100% !important; background: rgba(166,30,34,.5) !important;}
.MuiLinearProgress-bar { background: rgba(166,30,34,1) !important;}
.MuiCheckbox-root.Mui-checked { color: var(--blue) !important;}
.MuiPaper-root a { text-decoration: none !important;}
.MuiAvatar-circular.initials { background-color: var(--red) !important;}
.MuiAvatar-circular.image { background-color: var(--white) !important;}
.MuiRating-iconFilled { color: var(--red) !important;}
.MuiRating-iconEmpty { color: var(--light-grey) !important;}
.MuiBadge-badge { color: var(--white) !important; text-shadow: none !important; background: var(--red) !important;}
.MuiBadge-root { vertical-align: initial !important;}
.MuiDialog-container header { background: var(--blue) !important;}
.MuiDialog-paperFullScreen { overflow: hidden !important;}

/* File Drop */
.drop-file-input { position: relative; width: 50%; height: 200px; border: 2px dashed #c4c4c4; display: flex; align-items: center; justify-content: center; background-color: var(--ultra-light-grey);}
#editJobPopup .drop-file-input, .drop-file-input.single { height: 50px;}
form .drop-file-input { width: 100%;}
.drop-file-input input { opacity: 0; position: absolute; top: 0; left: 0; width: 100%; height: 100%; cursor: pointer;}
form .drop-file-input input { width: 100% !important; height: 100% !important;}
.drop-file-input:hover, .drop-file-input.dragover { background-color: rgba(243,243,243,.5)}
.drop-file-input__label { text-align: center; color: var(--black); padding: 10px;}
#editJobPopup .drop-file-input__label, .drop-file-input.single .drop-file-input__label  { display: flex;}
.drop-file-input__label svg { font-size: 100px;}
.drop-file-input.single .drop-file-input__label svg { font-size: 25px; margin-right: 10px; position: relative; top: 12px;}
#adminCreatePromotionPopup .drop-file-input__label svg { font-size: 25px; margin-right: 10px; position: relative; top: 0px;}
#editJobPopup .drop-file-input__label svg { font-size: 25px; margin-right: 10px; position: relative; top: 0px;}
#promotionsEditPopup .drop-file-input__label svg { font-size: 25px; margin-right: 10px; position: relative; top: 0px;}
.drop-file-preview { margin-top: 30px;}
.drop-file-preview__title { margin-bottom: 20px;}
.drop-file-preview__item { position: relative; display: flex; margin-bottom: 10px; background-color: var(--ultra-light-grey); padding: 15px; width: 50%; font-size: 14px;}
form .drop-file-preview__item { width: 100%;}
.drop-file-preview__item svg { font-size: 35px; margin-right: 10px;}
.drop-file-preview__item__info { display: flex; flex-direction: column; justify-content: space-between;}
.drop-file-preview__item__del { background-color: var(--ultra-light-grey); width: 40px; height: 40px; border-radius: 50%; display: flex; align-items: center; justify-content: center; position: absolute; right: 10px; top: 50%; transform: translateY(-50%); cursor: pointer; opacity: 0; transition: opacity 0.3s ease; font-size: var(--primary-font-size);}
.drop-file-preview__item:hover .drop-file-preview__item__del { opacity: 1;}
button.drop-file { width: 50%; position: relative}
button.drop-file.uploading { background: var(--light-grey); color: var(--red);}
form button.drop-file { width: 100%;}
form button.drop-file.uploading { background: var(--light-grey); color: var(--red);}


.drop-file-preview__title span { display: none;}
.drop-file-preview__title span.fileTypeError { color: var(--red); display: block;}
.drop-file-preview__title span.fileTypeError svg { position: relative; top: 4px;}
#editJobPopup .drop-file-preview__item.hide { display: none;}
#editJobPopup .drop-file-preview__title span { display: inline-block; text-decoration: underline; color: var(--blue); cursor: pointer;}

#editProfile form .drop-file-preview__item { display: flex;}
#editProfile form .drop-file-preview__item svg { flex: 0 0 5%;}
#editProfile form .drop-file-preview__item__info { flex: 0 0 90%; padding-right: 20px; justify-content: space-between; flex-direction: row;}
#editProfile form .drop-file-preview__item__del { flex: 0 0 5%; background: none; border-radius: 0; height: auto; width: auto; position: relative; right: 10px; top: 0; transform: none; display: block;}
#editProfile form .drop-file-preview__item__del:hover { color: var(--red);}

body.editing article.inner { position: relative;}
body.editing article.inner form { position: absolute; top: 0; right: 0;}
body.editing article.inner button.changeBanner { position: absolute; top: -20px; right: 0;}
body.editing article.inner form .drop-file-input { margin-top: 0;}

.certificatesUploader { margin-top: 35px;}
.column .certificatesUploader { margin-top: 27px;}
.column .certificatesUploader .drop-file-input { height: 163px}
.column .certificatesUploader .drop-file-input__label svg { font-size: 50px;}

.iyhBorderBox#certs .content { flex: 1; width: calc(100% - 75px);}
#certAwardsImageWrapper { display: flex; flex-wrap: wrap; gap: 20px; margin: 20px 0;}
#certAwardsImageWrapper li { /* flex: 0 0 calc(25% - (60px / 3)); */ height: 100px; list-style: none;}
#certAwardsImageWrapper .image { height: 100%;}
#certAwardsImageWrapper .image img { height: 100%;}

/* Gallery */
.image-gallery-icon:hover { background: none !important; color: var(--blue) !important;}
/* .image-gallery-slide .image-gallery-image { object-fit: cover !important;} */
.image-gallery-thumbnails-container button { margin-top: 0 !important;}
.image-gallery-thumbnail .image-gallery-thumbnail-image { height: 100px !important; object-fit: cover !important;}

/* Legal */
main.legal {}
.legalPopupEditor .tox-tinymce { height: calc(100vh - 180px) !important;}
main.legal h1 { color: var(--red);}
main.legal ol, main.legal ul { margin-top: 30px; }
main.legal ol li, main.legal ul li { margin-left: 30px; }
main.legal header { border-bottom: 1px solid var(--light-grey); margin-bottom: 50px;}
main.legal footer { border-top: 1px solid var(--light-grey); margin-top: 50px;}
main.legal .logoWrapper { position: relative; left: -12px;}
main.legal article { width: 80%;}
main.legal section { display: flex; flex-direction: row; width: 80%; margin: 0 auto;}
/* main.legal section article { width: auto; margin-top: 0;} */
main.legal section aside { flex: 0 0 30%; margin-right: 30px;}
main.legal section aside #legal a { display: block; font-size: 18px; margin-bottom: 10px;}
main.legal section aside #legal a.active { color: var(--black); font-weight: bold;}

/* Admin Legal Docs */
ul.legalDocs { margin-top: 20px;}
ul.legalDocs li { list-style: none; }
ul.legalDocs li button.edit { background: transparent; cursor: pointer; color: var(--red); margin-top: 0;}
ul.legalDocs li button.edit:hover { background: transparent;}

/* Denver Home Show Landing Pages */
.landing-page-form {
  display: flex;
  flex-direction: row;
}
.landing-page-form .form-div {
  width: 100%;
}
.landing-page-form .form-div form {
  width: 85%;
}
.landing-page-form .form-cta-div {
  width: 30%;
}
.landing-page-form .cta-card {
  background: #00968F;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: calc(27px + 1rem) 0 0 0;
  padding: 0 1rem;
}
.landing-page-form .cta-card h5 {
  /* font-size: 29.42px; */
  font-weight: 400;
  margin: 1rem 0;
}
.landing-page-form .cta-card h5 {
  color: white;
}
.landing-page-form .cta-card h5 span {
  font-weight: 600;
  font-size: 1.75rem;
}
.landing-page-form .cta-card .line-display {
  width: 80%;
  height: 1px;
  background: white;
  align-self: center;
}


/* Loading Animation */
.lds-grid {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  left: 50%;
  transform: translateX(-50%);
}
.lds-grid div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: var(--red);
  animation: lds-grid 1.2s linear infinite;
}
.lds-grid div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}
.lds-grid div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}
@keyframes lds-grid {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

@-webkit-keyframes waterfall {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-250%);
            transform: translateY(-250%); }
  40%,
  60% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(250%);
            transform: translateY(250%); } }
@keyframes waterfall {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-250%);
            transform: translateY(-250%); }
  40%,
  60% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(250%);
            transform: translateY(250%); } }


.waterfall div {
  -webkit-animation: waterfall 1.5s infinite;
          animation: waterfall 1.5s infinite;
  background-color: var(--red);
  height: 20px;
  left: 50%;
  margin-top: -10px;
  opacity: 0;
  position: relative;
  transform: translateX(-50%);
  width: 20px;
  }
  .waterfall div:nth-of-type(1) {
    -webkit-animation-delay: 0.25s;
            animation-delay: 0.25s;
    margin-left: -10px; }
  .waterfall div:nth-of-type(2) {
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
    margin-left: 15px; }
  .waterfall div:nth-of-type(3) {
    -webkit-animation-delay: 0.75s;
            animation-delay: 0.75s;
    margin-left: -35px; }
  .waterfall div:nth-of-type(4) {
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
    margin-left: 40px; }
  .waterfall div:nth-of-type(5) {
    -webkit-animation-delay: 1.25s;
            animation-delay: 1.25s;
    margin-left: -60px; }

/* columns container */
.columns-3 {
  width: 100%;
  display:flex;
}
/* columns */
.columns-3 > .column {
  width: calc(100% / 3);
  text-align:center;
  padding: 2.5rem;
}

.how-homeowners-choose-title {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 1.5rem;
}

.how-homeowners-choose-wrapper {
  padding: 2rem;
  background-color: #F3F3F3;
  border-top: 10px solid #0074A9;
  border-bottom: 10px solid #0074A9;
}

.comparison-table {
  margin: 1.5rem auto;
  text-align: center;
  border-collapse: collapse;
  width: 1100px;
}

.comparison-table tbody tr td {
  padding:1rem;
  font-weight: bold;
}

.comparison-table td {
  font-size: 18px;
  color: #515050;
}

.comparison-table th {
  color: #6E6E6E;
}

.comparison-table-title {
  font-size: 24px;
  font-family: "Inter", sans-serif;
  font-style: italic;
  font-weight: 400;
  text-align: left;
  padding-left: 1rem;
}

.conparison-table-site {
  font-size: 35px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
}

.comparison-table thead th:nth-of-type(2){
  padding-right: 1rem;
}

.comparison-table tbody tr td:nth-of-type(1){
  text-align: left;
}

.comparison-table tbody tr:nth-child(odd) {
  background-color: #F3F3F3;
}
.text-blue {
  color: #0074A9;
}

@media screen and (max-width: 1100px) {
  .comparison-table {
    width: 100%;
  }
}

/* Hero */
.hero-comp {
  height: fit-content; 
  position: relative;
  min-height: fit-content;
  text-align: left;
  padding-bottom: 2rem;
}
.hero-comp h2 {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 45px;
}
.hero-comp h2 span {
  font-weight: 800;
  font-style: normal;
  color: #0074A9;
}

.hero-comp h4 {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 24px;
  color: #4A4B4B;
  padding-top: 1rem;
}
.hero-comp button {
  background: #8E2327;
  margin-top: 2rem;
}
.hero-comp button:hover {
  background: #0074A9;
}
.hero-comp button a {
  text-decoration: none;
  color: white;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  padding-right: 1rem;
}
.hero-comp svg {
  font-size: 38px;
  position: absolute;
  top: 2px;
  right: 0px;
}
.hero-comp .hero-grid {
  padding-top: 5%;
}
.hero-comp .hero-video-grid {
  padding-right: 24px;
}
.landing-page-hero-comp .hero-comp {
  display: flex;
  align-items: center;
  text-align: left;
}
.landing-page-hero-comp .hero-comp .hero-grid {
  padding-top: 0;
}
.landing-page-hero-comp .hero-comp .hero-grid .hero-line-divider {
  height: 5px;
  background-color: #0074A9;
}
.landing-page-hero-comp .hero-comp .hero-grid  h2{
  padding-top: 0;
  font-style: normal;
}
.landing-page-hero-comp .hero-comp h3 {
  color: #0074A9;
  text-transform: capitalize;
  margin-bottom: 0;
}
.landing-page-hero-comp .hero-comp .hero-grid  h4{
  padding-top: 0;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0;
}
.landing-page-hero-comp .hero-comp .hero-grid  span{
  font-weight: 800;
  font-style: normal;
  color: #0074A9;
  font-size: 45px;
  /* text-transform: uppercase; */
}
.landing-page-hero-comp .hero-comp .hero-grid  sub{
  font-weight: 400;
  font-style: italic;
  color: #4A4B4B;
  font-size: 14px;
}
.landing-page-hero-comp .hero-comp .hero-grid p span {
  margin-bottom: 0;
  line-height: 1.5rem;
}
.landing-page-hero-comp .hero-comp .hero-grid p {
  margin-bottom: 0;
  font-weight: 300;
  color: #4A4B4B;
}
.landing-page-hero-comp .hero-comp .hero-grid i {
  font-size: 14px;
  display: block;
}
.landing-page-hero-comp img {
  left: 0;
}
.landing-page-hero-comp .form-hero-large-text {
  font-weight: 800;
  font-size: 105px;
}
.landing-page-hero-comp h5 {
  color: #4A4B4B;
  margin-top: 0.5rem;
  font-weight: 300;
}

/* Image Copy */
.copy-col {
  padding-bottom: 2rem;
}
.img-col {
  padding-bottom: 2rem;
}
.copy-col h2{
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 45px;
  margin-bottom: 2rem;
}
.copy-col h2 span {
  font-family: "Inter", sans-serif;
  font-size: 45px;
  font-weight: 800;
  color: #0074A9;
}

.copy-col h4{
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 24px;
  margin-bottom: 2rem;
}
.copy-col p{
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
}

.copy-col p span {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: #0074A9;
}
.copy-col button {
  background: #8E2327;
  margin-top: 2rem;
}
.copy-col button:hover {
  background: #0074A9;
}
.copy-col button a {
  text-decoration: none;
  color: white;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  padding-right: 1rem;
}
.copy-col svg {
  font-size: 38px;
  position: absolute;
  top: 2px;
  right: 0px;
}

/* Cta Block */
.cta-block-wrap {
  background: #8E2327;
  color: white;
  text-align: center;
  padding: 0 20%;
}
.cta-block {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.cta-block h2 {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 45px;
  margin-bottom: 1rem;
}
.cta-block h2 span {
  font-weight: 800;
  font-style: normal;
}
.cta-block h4{
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 24px;
  margin-bottom: 2rem;
}
.cta-block button {
  background: #C99E61;
  margin-top: 2rem;
}
.cta-block button:hover {
  background: #0074A9;
}
.cta-block button a {
  text-decoration: none;
  color: white;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  padding-right: 1rem;
}
.cta-block svg {
  font-size: 38px;
  position: absolute;
  top: 2px;
  right: 0px;
}

.testimonial-card {
  background-color: #0074A9 !important;
  color: white !important;
  border-radius: 0.3125em;
  border: none;
  box-shadow: 0px 0.1875em 0.625em rgba(0, 0, 0, 0.08);
  padding: 1.75rem 2rem 2.125rem;
  width: 97.5%;
  min-width: 12rem;
  max-width: 25rem;
}

.testimonial-block {
  padding: 4rem 0;
}

.testimonial-block h2 {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 45px;
  margin-bottom: 1rem;
  text-align: center;
  max-width: 30rem;
  color: #4A4B4B;
}
.testimonial-block h2 span {
  font-weight: 800;
  font-style: normal;
  color: #0074A9;
}
.testimonial-block h4{
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 24px;
  margin-bottom: 2rem;
  text-align: center;
  max-width: 30rem;
  color: #4A4B4B;
}

.testimonial-text-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Homeshow Banner Styling */
/* .banner-link {
  min-height: 100px;
} */
.banner-link img {
  width: 100%;
  border: 1px solid gray;
}


/* Carousel Styling */
.slick-loading .slick-list {
  background: #fff url("slick-carousel/slick/ajax-loader.gif") center center
    no-repeat;
}


@font-face {
  font-family: "slick";
  font-weight: normal;
  font-style: normal;

  src: url("slick-carousel/slick/fonts/slick.eot");
  src: url("slick-carousel/slick/fonts/slick.eot?#iefix")
      format("embedded-opentype"),
    url("slick-carousel/slick/fonts/slick.woff") format("woff"),
    url("slick-carousel/slick/fonts/slick.ttf") format("truetype"),
    url("slick-carousel/slick/fonts/slick.svg#slick") format("svg");
}

.slick-track {
  height: fit-content !important;
}

.slick-slider > .slick-prev,
.slick-slider > .slick-next {
  font-size: 0;
  line-height: 0;

  position: absolute;

  display: block;

  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: auto;
  bottom: -5rem;

  cursor: pointer;

  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;

  opacity: 0.75;
  color: white;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-slider > .slick-prev {
  left: auto;
  right: calc(7% + 4rem);
}
.slick-prev:before {
  content: "";
  display: none;
}
[dir="rtl"] .slick-prev:before {
  content: "";
}

.slick-slider > .slick-next {
  right: 7%;
}
.slick-next:before {
  content: "";
  display: none;
}
[dir="rtl"] .slick-next:before {
  content: "";
}


.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -60px;

  display: block;

  width: 100%;
  padding: 0;
  margin: 0;

  list-style: none;

  text-align: center;
}
.slick-dots li {
  position: relative;

  display: inline-block;

  width: 34px;
  height: 34px;
  margin: 0 5px;
  padding: 0;

  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;

  display: block;

  width: 34px;
  height: 34px;
  padding: 5px;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  font-family: "slick";
  font-size: 16px;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  width: 34px;
  height: 34px;

  content: "•";
  text-align: center;

  opacity: 0.25;
  color: black;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  opacity: 1;
  color: #84c447;
}

.slick-slide {
  padding: 15px;
  outline: none !important;
}

.arrow-right svg{
  bottom: 0;
  color: #0074A9;
  position: absolute;
}

.slick-disabled svg{
  color: #BABBBB;
}

.project-icon-block-wrapper {
  padding: 4rem 0;
}

.project-icon-block-wrapper h2 {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 45px;
  margin-bottom: 1rem;
  color: white;
  text-align: center;
}
.project-icon-block-wrapper h2 span {
  font-weight: 800;
  font-style: normal;
}
.project-icon-block-wrapper h4{
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 24px;
  margin-bottom: 2rem;
  color: white;
}

.project-icon-block-wrapper .icon-div {
  background-color: white;
  padding: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 186px;
  border-radius: 12px;
}

.project-icon-block-wrapper .icon-col {
  display: flex;
  justify-content: center;
  padding-bottom: 1.5rem;
}

.project-icon-block-wrapper .icon-col a {
  text-decoration: none;
  color: #4A4B4B;
}

.project-icon-block-wrapper .icon-col p {
  margin: 0.5rem 0;
}

.step-display h2 {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 45px;
  margin-bottom: 1rem;
  text-align: center;
  max-width: 30rem;
  color: #4A4B4B;
}
.step-display h2 span {
  font-weight: 800;
  font-style: normal;
  color: #0074A9;
}
.step-display h4{
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 24px;
  margin-bottom: 2rem;
  text-align: center;
  max-width: 30rem;
  color: #4A4B4B;
}
.step-display img {
  width: 100%;
}

.step-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.partners-wrap {
  padding: 3rem 0;
}

.partners-wrap .ribbon-text{
  background-color: #0074A9;
  clip-path: polygon(0 0, 97% 0, 100% 100%, 0% 100%);
  width: 67%;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  margin-bottom: 2rem;
}

.partners-wrap h2{
  text-align: right;
  padding-right: 2rem;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 35px;
  margin-bottom: 0rem;
  color: white;
}

.partners-wrap span{
  font-weight: 800;
  font-style: normal;
}

.logo-col {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2%;
}

.logo-col img{
  width: 100%
}

.popup-agreement-text {
  text-decoration: underline;
  /* color: blue; */
}