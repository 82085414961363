/* IYH Mobile CSS */

:root{
  --mobile-content-width: 90%;
}

@media all and (max-width: 1300px)  {
	.hero-comp .hero-grid {
		padding-top: 7%;
	}
}


@media all and (max-width: 1150px)  {

	/* Backend Mobile Menu Button */
	button.backendMobileMenu { display: inline-block; background: var(--red); width: 100%;}
	button.backendMobileMenu.open { background: var(--grey);}
	button.backendMobileMenu svg { position: relative; top: 3px;}
	section nav.mobile#user-menu li { margin-bottom: 0;}
	nav.mobile#user-menu, nav.mobile#contractor-menu, nav.mobile#admin-nav { margin-top: 0;}
	nav.mobile {display: none;}
	nav.mobile.open {display: block;}

	/* Adjust width of global containers */
	article { width: var(--mobile-content-width); margin: 35px auto;}
	main.legal article { width: 80%;}


	/* Switch Backend Menus to mobile */
	.backendColumnWrapper { flex-direction: column;}
	.backendColumnWrapper .column#menu { margin-right: 0;}

	/* adjust pro spotlight */
	.featuredContractor a { flex-wrap: wrap;}
	.featuredContractor .cover { flex: 0 0 100%;}
	.featuredContractor .contentWrapper { flex: 0 0 100%; left: 0; display: flex; justify-content: center; top: -10%;}
	.featuredContractor .contentWrapper .content { flex: 0 0 75%;}
	.featuredContractor .pagination { margin: 0 0 50px 0;}

	/* Legal Pages */
	main.legal section { flex-direction: column;}
	main.legal aside { margin-bottom: 35px; border-bottom: 1px solid var(--light-grey); padding-bottom: 35px;}

	/* HomePro Page */
	.hero-comp h2 {
		font-size: 40px;
	}

	.hero-comp h4 {
		font-size: 20px;
	}
 .hero-text h2 {
		font-size: 20px;
	}
	.landing-page-hero-comp .hero-comp .hero-grid .hero-text span {
		font-size: 25px;
	}
	.landing-page-hero-comp .form-hero-large-text {
		font-size:60px;
	}
}

@media all and (max-width: 1020px)  {
	header .logo { height: 75px;}
	header .search { font-size: 1.5rem; display: flex; align-items: center;}
}

@media all and (max-width: 1000px)  {
	/* switch to mobile menu */

	/* adjust header */
	/* header article.inner { flex-direction: column;} */
	header .logo { height: 50px;}
	main.legal header article.inner { flex-direction: row;}
	main.legal header { height: 100px; display: flex;}
	main.legal header .logo { height: 50px;}
	nav#top-nav {}

	/* Banner */
	section.banner { overflow: hidden; max-height: inherit; height: auto;}
	section.banner img { height: 100%; object-fit: cover; object-position: center;}

	section.banner .searchBoxWrapper { position: relative; margin: 70px 0;}

	/* Search Box */
	section.banner .searchBoxWrapper { padding: 0 5%;}
	section.banner .searchBox {}
	section.banner .searchBoxWrapper .serachBoxContent h2 { color: var(--black); text-shadow: none;}
	section.banner .searchBoxWrapper .serachBoxContent { text-align: center;}
	section.banner .searchBox { flex-wrap: wrap; background: var(--blue); border: 1px solid var(--white); padding: 15px; display: flex; flex-direction: row; width: 100%; gap: 15px; position: relative;}
	section.banner .searchBox .serviceSearchWrapper { flex: 0 0 100%;}
	section.banner .searchBox .zipcodeSearchWrapper { flex: 0 0 100%;}
	section.banner .searchBox > button { width: 100%; color: var(--white); background: var(--red); cursor: pointer;}
	section.banner .searchBox .MuiAutocomplete-endAdornment { display: flex; flex-direction: row;}
	section.banner .searchBox .MuiAutocomplete-endAdornment button { flex: 1;}
	.MuiAutocomplete-root .MuiOutlinedInput-root { background: var(--white) !important;}


	/* section.banner .searchBox form .serviceSearchResults { z-index: 9;}
	section.banner .searchBox form .zipcodeSearchResults { z-index: 9;} */

	/* stack home page local contractor */
	ul.contractorList { flex-wrap: wrap;}
	ul.contractorList li { flex: 0 0 100%; height: 400px;}
	ul.contractorList .pictureLi {height: 200px;}

	/* Stack Footer */
	footer .columnWrapper { flex-wrap: wrap;}
	footer .column { flex: 0 0 100%;}
	footer .column img { width: 50%;}
	footer .column:nth-of-type(1) { order: 1;}
	footer .column:nth-of-type(2) { order: 3;}
	footer .column:nth-of-type(3) { order: 2;}

	/* Contractor Profiles */
	.contractorContent .entryContent { flex-direction: column;}
	.contractorContent .contractorContact { margin-left: 0; margin-top: 20px;}
	.contractorHeader .coverPhotoWrapper img { height: 100%; object-fit: cover; object-position: center;}

	/* Search Results */
	.titleButtonSection h2 {
		display: block;
	}
	.titleButtonSection a { 
		position: relative;
		right: 0;
		width: 8rem;
		display: block;
	}
	.titleButtonSection a { 
		margin-bottom: 0 !important;
	}
}

@media all and (max-width: 940px)  {
	ul.contractorTabs { display: none;}
}

@media all and (max-width: 900px)  {
	/* Adjust Contractor Header */
	.businessInfo { flex-direction: column;}
	.contractorList .businessInfo { flex-direction: row;}
	.businessInfo .logoWrapper { margin-left: 0; width: 33%;}
	.businessInfo .content { margin-left: 0; color: var(--black);}
	.businessInfo .title { margin-top: 20px; }
	.businessInfo .specialty { text-align: center;}
	.businessInfo .title h1 { font-size: 1.5em; text-shadow: 2px 2px 2px var(--ultra-light-grey); color: var(--black); }
	.contractorHeader .headerActionButtons { position: relative; margin-top: 15px; border-top: 1px solid var(--light-grey); padding-top: 20px;}
	.contractorHeader .headerActionButtons .actionsContainer li { color: var(--red);}

	/* Keep home page business info the same */
	section.contractorCategoryBlock .businessInfo { flex-direction: row !important;}

	/* Backend Lists */
	.backendList li.key { display: none !important;}
	.backendList li { flex-wrap: wrap !important;}
	.backendList li div { flex: 0 0 100% !important; font-size: 20px;}
	.backendList div.buttons { flex-wrap: wrap; margin-bottom: 20px;}
	.backendList div.buttons div { flex: 0 0 25% !important; margin: 0; text-align: center; font-size: 18px;}
	.backendList div.buttons div svg { fill: var(--white); position: relative; top: 3px; margin-left: 0px;}
	.backendList div.buttons div:before { display: inline-block; font-weight: normal; text-transform: uppercase;}
	.backendList div.buttons .edit { background: var(--black); color: var(--white);}
	.backendList div.buttons .edit:before { content: 'edit';}
	.backendList div.buttons .view { background: var(--grey); color: var(--white);}
	.backendList div.buttons .view:before { content: 'view';}
	.backendList div.buttons .share:before { content: 'share';}
	.backendList div.buttons .share { background: var(--blue); color: var(--white);}
	.backendList div.buttons .delete:before { content: 'delete';}
	.backendList div.buttons .delete { background: var(--red); color: var(--white);}
	.backendList div.buttons .preview { background: var(--blue); color: var(--white);}
	.backendList div.buttons .preview:before { content: 'preview';}
	.backendList div.buttons .archive:before { content: 'archive';}
	.backendList div.buttons .archive { background: var(--grey); color: var(--white);}
	.backendList div.buttons .accountLocked:before { content: 'Account';}
	.backendList div.buttons .accountLocked { background: green; color: var(--white);}
	.backendList div.buttons .accountLocked.locked { background: var(--black);}
	.backendList div.buttons .reviewsLocked:before { content: 'Reviews';}
	.backendList div.buttons .reviewsLocked { background: orange; color: var(--white);}
	.backendList div.buttons .reviewsLocked.locked { background: var(--black);}


	/* Reviews */
	ul.reviewList div:before { display: inline-block !important; font-weight: bold; margin-right: 10px;}
	ul.reviewList div p:before { display: inline-block !important;}
	ul.reviewList div p { display: inline-block !important;}
	ul.reviewList .name:before { content: 'Homeowner:';}
	ul.reviewList .rating:before { content: 'Rating:';}
	ul.reviewList .date:before { content: 'Date of Review:';}
	ul.reviewList .statusContainer:before { content: 'Status:';}

	/* Projects */
	ul.jobsList div:before { display: inline-block !important; font-weight: bold; margin-right: 10px;}
	ul.jobsList div p:before { display: inline-block !important;}
	ul.jobsList div p { display: inline-block !important;}
	ul.jobsList .name:before { content: 'Project Name:';}
	ul.jobsList .user { text-align: left;}
	ul.jobsList .user:before { content: 'User ID:';}
	ul.jobsList .homeowner:before { content: 'Homeowner:';}
	ul.jobsList .client:before { content: 'Client:';}
	ul.jobsList .date:before { content: 'Date:';}
	ul.jobsList .statusContainer:before { content: 'Status:';}
	ul.jobsList .status { width: auto;}
	ul.jobsList .view { margin-bottom: 20px;}

	/* Promotions */
	ul.promotionsList div:before { display: inline-block !important; font-weight: bold; margin-right: 10px;}
	ul.promotionsList div p:before { display: inline-block !important;}
	ul.promotionsList div p { display: inline-block !important;}
	ul.promotionsList .name:before { content: 'Name:';}
	ul.promotionsList .date:before { content: 'Date:';}
	ul.promotionsList .statusContainer:before { content: 'Status:';}
	ul.promotionsList .status { width: auto;}

	/* Tickets */
	ul.ticketList div:before { display: inline-block !important; font-weight: bold; margin-right: 10px;}
	ul.ticketList div p:before { display: inline-block !important;}
	ul.ticketList div p { display: inline-block !important;}
	ul.ticketList .user:before { content: 'From:';}
	ul.ticketList .subject:before { content: 'Subject:';}
	ul.ticketList .type { line-height: 50px;}
	ul.ticketList.admin .type { line-height: 50px;}
	ul.ticketList .type:before { content: 'Type:';}
	ul.ticketList .date:before { content: 'Date:';}
	ul.ticketList .date, ul.ticketList.admin .date { padding-left: 0;}
	ul.ticketList .statusContainer:before { content: 'Status:';}
	ul.ticketList.admin .statusContainer { text-align: left;}
	ul.ticketList .status { width: auto;}

	/* Markets */
	ul.marketsList div:before { display: inline-block !important; font-weight: bold; margin-right: 10px;}
	ul.marketsList div p:before { display: inline-block !important;}
	ul.marketsList div p { display: inline-block !important;}
	ul.marketsList .name:before { content: 'Name:';}
	ul.marketsList .date:before { content: 'Date:';}
	ul.marketsList .statusContainer:before { content: 'Status:';}
	ul.marketsList .status { width: auto;}
	ul.statesList { column-count: 1;}

	/* Coupons */
	ul.couponList div:before { display: inline-block !important; font-weight: bold; margin-right: 10px;}
	ul.couponList div p:before { display: inline-block !important;}
	ul.couponList div p { display: inline-block !important;}
	ul.couponList .name:before { content: 'Name:';}
	ul.couponList .code:before { content: 'Code:';}
	ul.couponList .statusContainer { text-align: left;}
	ul.couponList .statusContainer:before { content: 'Status:';}
	ul.couponList .status { width: auto;}

	/* Packages */
	ul.packageList div:before { display: inline-block !important; font-weight: bold; margin-right: 10px;}
	ul.packageList div p:before { display: inline-block !important;}
	ul.packageList div p { display: inline-block !important;}
	ul.packageList .name:before { content: 'Name:';}
	ul.packageList .price:before { content: 'Price:';}
	ul.packageList .statusContainer { text-align: left;}
	ul.packageList .statusContainer:before { content: 'Status:';}
	ul.packageList .status { width: auto;}

	/* Admin Users */
	ul.usersList div p:before { display: inline-block !important;}
	ul.usersList div:before { display: inline-block !important; font-weight: bold; margin-right: 10px;}
	ul.usersList div p { display: inline-block !important;}
	ul.usersList .id:before { content: 'User ID:';}
	ul.usersList .name:before { content: 'Name:';}
	ul.usersList .name p { width: 100%; top: 0; font-style: normal; font-size: 20px; color: var(--black);}
	ul.usersList .name p:before { content: 'Business:'; font-weight: bold; font-style: normal; font-size: 20px; color: var(--black); margin-right: 10px;}
	ul.usersList .loggedInDate:before { content: 'Last Logged In:';}
	ul.usersList .roleContainer:before { content: 'Role:';}
	ul.usersList .roleContainer { flex: 0 0 auto !important; display: inline-block;}
	ul.usersList .role { display: inline-block; top: 0;}
	.selectContainer .addUserWrapper { flex: 0 0 calc(33% - (40px / 3));}
	.addUserWrapper button.addUser { display: inline-block; width: 100%; text-align: right;}
	.addUserWrapper button.addUser svg { position: relative; top: 6px;}

	/* Chat Box */
	.chatBox .MuiTabs-vertical { flex: 0 0 100% !important;}
	.MuiTabs-vertical { flex: 0 0 300px !important; border-right: none !important; border-bottom: 1px solid var(--light-grey) !important;}
	.chatBox { height: auto !important;}

	/* Subscription Payment */
	.nameColumn { flex: 0 0 100%;}
	.billingColumn { flex: 0 0 100%;}
	.paymentColumnWrapper #cc { flex: 0 0 100%;}
	.paymentColumnWrapper #ex-month { flex: 0 0 100%;}
	.paymentColumnWrapper #ex-year { flex: 0 0 100%;}
	.paymentColumnWrapper #sc { flex: 0 0 100%;}

	.landing-page-hero-comp .hero-comp .hero-text h5 {
		font-size: 17px;
		margin-top: 0.25rem;
		margin-bottom: 0.25rem;

	}
	.favoriteList .delete span {
		top: -5px;
		position: relative;
	}
	.hero-comp button {
		margin-top: 1rem;
	}
	.hero-comp h4 {
		padding-top: 0.5rem;
	}
	.hero-comp .hero-video-grid {
		padding-right: 0px;
	}
}

@media all and (max-width: 800px)  {
	/* Adjust Header */
	a.search { display: flex; align-items: center; font-size: 1.5rem;}
	.mobileMenu { background: transparent; color: var(--black); margin-top: 0; font-size: 1.5rem; display: flex;}
	.mobileMenu:hover { background: transparent !important; color: var(--red);}
	.headerButtons { display: flex; align-items: center;}
	span.welcome { font-size: .75em; padding: 10px 0;}

	/* Adjust Banner */
	/* section.banner { height: 600px;} */

	/* Footer */
	footer .sub__footer article { display: block;}
	footer .sub__footer { line-height: 1.5; padding: 20px 0;}
	footer .sub__footer #copy { margin-bottom: 0px; }
	footer .sub__footer #credit { font-size: 12px; }
	footer .sub__footer #copy br { display: none;}
	footer .sub__footer #copy span { padding-left: 10px;}
	footer .logo { height: 50px;}
	footer ul li { display: inline-block; font-size: 12px;}
	footer ul li:not(:last-of-type):after { content: '|'; padding: 0 10px; display: inline-block; color: var(--white);}

	/* .hero-comp svg {
		top: 0px;
	} */

	/* CTA Block */
	.cta-block-wrap {
		padding: 0 8%;
	}

	.cta-block h2{
		font-size: 35px;
	}

	.cta-block h4 {
		font-size: 20px;
	}

	.cta-block a {
		font-size: 16px;
	}
	.cta-block svg {
		top: 0px;
	}

	.columns-3 {
    display: block;
  }

  .columns-3 > .column {
    width: 100%;
  }

  .comparison-table td {
    font-size: 11px;
  }

	.comparison-table th {
		font-size: 11px;
	}

	#certAwardsImageWrapper {
		padding-left: 0;
	}

	#certAwardsImageWrapper li {
		width: 100%;
    overflow: hidden;
	}

	/* #certAwardsImageWrapper .image img {
		overflow: scroll;
	} */
}

@media all and (max-width: 775px)  {
	/* Subscription state notes */
	.subscriptionNote { border: none; padding: 0;}
	.noteWrapper { border: 1px solid var(--red); padding: 5px 25px;}

	/* Legal Pages */

	/* HomePro Page Partners Section */
	.partners-wrap .ribbon-text{
		clip-path: polygon(0 0, 95% 0, 100% 100%, 0% 100%);
		width: 88%;
	}

	.partners-wrap h2{
		font-size: 28px;
	}

	.testimonial-block h2 {
		font-size: 28px;
		max-width: 100%;
	}

	ul.contractorList li {
    flex: 0 0 100%;
    height: fit-content;
		min-height: 90px;
	}
	ul.contractorList li .searchImageLink .cover img {
		min-height: 90px;
	}
	.contractorDescription {
		padding: 0 0 1rem;
	}
	.mobileButtonCol {
		display: flex;
		justify-content: center;
		border-bottom: 1px solid #bfbfbf;
    color: #4a4b4b;
		padding-bottom: 2rem;
	}
	.mobileButtonCol a {
		width: 8rem;
		position: relative;
	}
	.mobileButtonCol a svg{
		position: absolute;
		right: 10px;
		top: 13px;
		font-size: 22px;
	}
	.mobileButtonRow {
		padding-bottom: 3rem;
		border-bottom: 1px solid #BFBFBF;
		color: #4A4B4B;
	}
	.mobileContractorHead {
		padding-top: 4rem;
	}
	.mobileContractorHead:first-child {
		padding-top: 1rem;
	}
	ul.contractorList .mobileContractorHead li a {
		display: inline;
	}
	.titleButtonSection{ 
		padding-bottom: 0;
		padding-top: 0.5rem;
		margin-bottom: 0.1rem;
		font-size: 20px;
	}
	.titleButtonSection a .button-red { 
		margin-bottom: 35px !important;
	}
	.categoryCol .categoryTag p {
		width: 100%;
    overflow: hidden;
	}
	.searchRatingSection {
		padding-top: 0;
	}
	.searchRatingSection span {
		font-size: 29px;
	}
	.contractorDescription {
		--line-clamp: 3;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: var(--line-clamp);
		-webkit-box-orient: vertical;
		padding-bottom: 0;
	}
	article.inner .contractorDescription {
    margin-bottom: calc(10px + 1rem);
	}
	.titleButtonSection a {
		width: 100%;
	}
	.landing-page-hero-comp .hero-comp .hero-grid {
		justify-content: center;
	}
	
	.landing-page-hero-comp .hero-comp .hero-text {
		background:rgba(255,255,255, 0.9);
		padding: 1rem;
		padding-left: 1rem;
		padding-top: 1rem;
	}
	.landing-page-hero-comp .form-hero-large-text {
		font-size: 65px;
	}
	.landing-page-hero-comp .hero-comp .hero-grid  span{
		font-size: 30px;
	} 

	.landing-page-form {
		flex-direction: column;
	}
	.landing-page-form .form-cta-div {
		width: 100%;
		order: 1;
	}
	.landing-page-form .form-cta-div .cta-card {
		margin: 0;
	}
	.landing-page-form .form-div {
		order:2;
	}
	.landing-page-form .form-div form {
		width: 100%;
	}
	
	.landing-page-hero-comp .hero-comp .hero-grid .hero-text span {
		font-size: 30px;
	}
	.hero-comp h2 {
		font-size: 30px;
	}
	.hero-comp h4 {
		font-size: 16px;
	}
}

@media all and (max-width: 700px)  {
	/* Wrap Contractor Videos */
	.contractorVideos .inner .videoContainer { flex-wrap: wrap;}
	.contractorVideos .inner .videoContainer li { flex: 0 0 100%;}

	/* Login/Signup Form */
	form { width: 100%;}

	.step-display h2{
		font-size: 28px
	}
	.step-display h4{
		font-size: 20px
	}
}

@media all and (max-width: 650px)  {
	/* Stack sub footer */
	footer .sub__footer { line-height: 1.5; padding: 20px 0;}
	footer .sub__footer article { flex-direction: column-reverse;}

	/* Subscription Forms */
	.servicesContainer .serviceWrapper { flex: 0 0 100%;}
	.servicesContainer .selectedCategories { flex: 0 0 100%;}

}

@media all and (max-width: 600px)  {

	/* Contractor Profile */
	.contractorHeader .coverPhotoWrapper { height: 300px;}

	/* Contractor Dashboard */
	.dashboardItemWrapper .dashboardItem { flex: 0 0 100%;}
	.dashboardItemWrapper.stats .dashboardItem { flex: 0 0 50%;}

	/* Backend Select Container */
	.selectContainer { flex-wrap: wrap;}
	.selectContainer .selectWrapper { flex: 0 0 100%;}

	/* Backend buttons */
	.backendList div.buttons div { flex: 0 0 50% !important; margin: 0; text-align: center; font-size: 18px;}
	.selectContainer .addUserWrapper { flex: 0 0 100%;}
	.addUserWrapper button.addUser { background: var(--red); display: inline-block; width: 100%; text-align: center; color: var(--white); right: 0;}
	.addUserWrapper button.addUser:before { content: 'Add User'; display: inline-block; font-size: 25px; position: relative; top: -5px; margin-right: 20px;}

	/* Edit Profiles */
	#editProfile form #profilePhotos .formContentWrapper { flex-wrap: wrap;}
	#editProfile form #profilePhotos .column { flex: 0 0 100%;}
	#editProfile form section ul li { flex: 0 0 calc(20% - ((5px * 5) / 4))}
	.popupProAgreements .MuiPaper-root { min-width: auto !important;}
	.MuiPaper-root { min-width: auto !important;}
	.MuiDialogContent-root section {
		min-width: auto;
	}

	/* Account/Subscription */
	ul.availablePackages li:not(.cancel) { flex: 0 0 100%;}
	ul.availablePackages li.cancel div { flex: 0 0 100%;}
	
	/* Hero */
	.hero-comp h2 {
		font-size: 30px;
	}
	.hero-comp h4 {
		font-size: 16px;
	}

	.viewTicketPopup .ticket-action-buttons {
		flex-wrap: wrap;
	}
}

@media all and (max-width: 500px)  {
	/* Adjust Header */

	/* header article.inner { flex-direction: column-reverse;} */
	nav#top-nav ul { display: flex; flex-direction: row; flex-wrap: wrap; }
	nav#top-nav ul li { flex: 0 0 100%; margin-left: 0; text-align: center;}
	nav#top-nav ul li:nth-of-type(1) { order: 3;}
	nav#top-nav ul li:nth-of-type(2) { order: 2;}
	nav#top-nav ul li:nth-of-type(3) { order: 1;}

	/* Subscription Coupon Code */
	.couponColumnWrapper #coupon { flex: 0 0 100%;}
	.couponColumnWrapper button { margin-top: 0; margin-bottom: 20px; top: -25px; flex: 0 0 100%;}

	/* Adjust Footer */
	footer .sub__footer #copy br { display: block;}
	footer .sub__footer #copy span { padding-left: 0px;}

	.cta-block-wrap {
		padding: 0 0%;
	}

	.how-homeowners-choose-wrapper .column {
		padding: 1.5rem;
	}
	#googleReview .headerSection {
		flex-direction: column;
	}
	#googleReview .headerSection .button-red {
		width:fit-content;
	}
	.contractorContent .reviewsContainer {
		padding-top: 0;
	}
}


@media all and (max-width: 400px)  {
	/* Adjust Header */
	header .logo { height: 40px;}

	.searchRatingSection p {
		font-size: 19px;
		padding-right: 0.2rem;
	}
	.searchRatingSection span {
		font-size: 24px;
	}
	.hero-text h2 {
		font-size: 25px;
	}
	.landing-page-hero-comp .hero-comp .hero-grid .hero-text p {
		font-size: 18px;
	}
}



